import { useState } from "react";

export const DevelopersRelation = () => {
  const [state, setState] = useState(false);
  const tableItems = [
    {
      name: "More Scalable Community Engagement & Support",
      id: "1",
    },
    {
      name: "Faster Technical Content Creation",
      id: "2",
    },
    {
      name: "Better Developer Onboarding & Training",
      id: "3",
    },
    {
      name: "Highly Accurate Technical Support",
      id: "4",
    },
  ];

  return (
    <>
      <section className="py-48   bg-[#F3F5F9]">
        <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
          <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
            <h1 className="text-4xl text-blue-800 font-extrabold md:text-5xl">
              Sprouts AI for Developers Relations
            </h1>
            <h2 className="text-3xl text-gray-800 font-medium md:text-4xl">
              Enhance Your Developer Relations with GenAI Autonomous Agents
            </h2>
            <p>
              Forge stronger developer relationships, simplify technical
              communications, and ensure your developer community thrives with
              SproutsAI.
            </p>
            <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
              <a
                href="https://beta.sproutsai.com/signup"
                target="_blank"
                className="block py-2 px-4 text-center text-white font-medium bg-blue-600 duration-150 hover:bg-blue-500 active:bg-blue-700 rounded-lg shadow-lg hover:shadow-none"
              >
                SignUp for Free
              </a>

            </div>
          </div>
          {/* <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
            <div className="max-w-xl mx-auto px-4 md:px-8">
              <div className="mt-12 shadow-sm border bg-white rounded-lg overflow-x-auto">
                <table className="w-fit table-auto text-sm text-left">
                  <thead className=" text-gray-600 font-medium ">
                    <tr>
                      <th className="py-3 px-6 text-xl text-blue-600">
                        Sprouts AI Benefits for Developer Relations
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-black divide-y">
                    {tableItems.map((item, idx) => (
                      <tr key={idx}>
                        <td className="flex px-6 py-4 gap-5 whitespace-nowrap">
                          {item.name}
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.5 3.84216H4.5C4.10218 3.84216 3.72064 4.0002 3.43934 4.2815C3.15804 4.56281 3 4.94434 3 5.34216V20.3422C3 20.74 3.15804 21.1215 3.43934 21.4028C3.72064 21.6841 4.10218 21.8422 4.5 21.8422H19.5C19.8978 21.8422 20.2794 21.6841 20.5607 21.4028C20.842 21.1215 21 20.74 21 20.3422V5.34216C21 4.94434 20.842 4.56281 20.5607 4.2815C20.2794 4.0002 19.8978 3.84216 19.5 3.84216ZM16.2806 13.3728L13.2806 16.3728C13.1399 16.5135 12.949 16.5926 12.75 16.5926C12.551 16.5926 12.3601 16.5135 12.2194 16.3728C12.0786 16.2321 11.9996 16.0412 11.9996 15.8422C11.9996 15.6431 12.0786 15.4523 12.2194 15.3115L13.9397 13.5922H8.25C8.05109 13.5922 7.86032 13.5131 7.71967 13.3725C7.57902 13.2318 7.5 13.0411 7.5 12.8422C7.5 12.6433 7.57902 12.4525 7.71967 12.3118C7.86032 12.1712 8.05109 12.0922 8.25 12.0922H13.9397L12.2194 10.3728C12.0786 10.2321 11.9996 10.0412 11.9996 9.84216C11.9996 9.64314 12.0786 9.45227 12.2194 9.31154C12.3601 9.17081 12.551 9.09175 12.75 9.09175C12.949 9.09175 13.1399 9.17081 13.2806 9.31154L16.2806 12.3115C16.3504 12.3812 16.4057 12.4639 16.4434 12.555C16.4812 12.646 16.5006 12.7436 16.5006 12.8422C16.5006 12.9407 16.4812 13.0383 16.4434 13.1294C16.4057 13.2204 16.3504 13.3031 16.2806 13.3728Z"
                              fill="#2563EB"
                            ></path>
                          </svg>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <div className="max-w-2xl mx-auto text-center">
        <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
        4 Ways Sprouts AI Creates Value For Developer Relations
        </h3>
        <p className="mt-3">
        Enable your Developer Relations team to leverage the power of generative AI.
        </p>
      </div> */}
    </>
  );
};
