import React from "react";

export default function IndustrySolution() {

  const industries = [
    { name: "Banking & Financial services", image: "https://anima-uploads.s3.amazonaws.com/projects/64e053ca6f2d8c732688afbf/releases/64e07ff26c377e691218a852/img/group@2x.png" },
    { name: "Information technology", image: "https://anima-uploads.s3.amazonaws.com/projects/64e053ca6f2d8c732688afbf/releases/64e07ff26c377e691218a852/img/frame-5.svg" },
    { name: "Manufacturing and Process", image: "https://anima-uploads.s3.amazonaws.com/projects/64e053ca6f2d8c732688afbf/releases/64e07ff26c377e691218a852/img/frame-6.svg" },
    { name: "Real Estate and construction", image: "https://anima-uploads.s3.amazonaws.com/projects/64e053ca6f2d8c732688afbf/releases/64e07ff26c377e691218a852/img/frame-7.svg" },
    { name: "Healthcare", image: "https://anima-uploads.s3.amazonaws.com/projects/64e053ca6f2d8c732688afbf/releases/64e07ff26c377e691218a852/img/frame-8.svg" },
    { name: "Semiconductor", image:"https://anima-uploads.s3.amazonaws.com/projects/64e053ca6f2d8c732688afbf/releases/64e07ff26c377e691218a852/img/frame-9.svg" },
  ]
  return (
    <div className="bg-[#F3F5F9]">
      <div className="relative h-[30vh]">
        <img
          src="https://www.themetechmount.com/react/hireco/images/pagetitle-bg.jpg"
          className="absolute inset-0 object-cover w-full h-full"
          alt=""
        />
        <div className="relative bg-gray-900 h-full bg-opacity-70">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between ">
              <h1 className='text-[#FFF] text-[32px] not-italic font-semibold'>Industries</h1>

            </div>
          </div>
        </div>
      </div>

      <section className="py-14">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 ">
            <div className="grid  grid-cols-3  gap-[40px]">
              {industries.map((item, idx) => (
                <div className="bg-[#FFF] flex justify-center flex-col items-center py-[100px] rounded-[30px]">
                  <img
                    className="w-[80px] h-auto"
                    alt={item.image}
                    src={item?.image}
                  />

                  <h1 className="mt-[40px] text-[20px] font-semibold leading-7">{item.name}</h1>

                </div>
              ))}
            </div>
        </div>
        </div>
      </section>
      <div className={`flex flex-col items-center  bg-gray-50 `}>
          <div className="flex flex-col       max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
            <section className="overflow-hidden  sm:grid sm:grid-cols-2">
              <div className="  lg:py-24">
                <div
                  className="mx-auto max-w-xl  ltr:sm:text-left rtl:sm:text-right"
                >
                  <span className="text-[#16182F] font-normal text-[14px] leading-[1px] text-center">Looking for <span className='text-[#1369E9]'>an expert!</span> </span>
                  <h2 className="text-2xl font-bold text-gray-900 md:text-3xl mt-[10px]">
                    Find top talents from sproutium.
                  </h2>
  
                  <p className="hidden text-gray-500 md:mt-4 md:block">
                  We're a team of seasoned recruiting professionals leveraging the latest AI technology to source candidates from an expansive pool of over 800 million profiles on platforms like LinkedIn, GitHub, and Stackoverflow. Our use of advanced AI goes beyond sourcing. We assist with talent branding by enhancing job descriptions through our own generative AI, ensuring they resonate with the right candidates. With Sproutium, you'll experience hiring that's not only effective but incredibly cost-efficient.
                  </p>
  
                  <div className="mt-4 md:mt-8">
                    <a
                      href="#"
                      className="inline-block rounded  px-12 py-3 text-sm font-medium text-[#1369E9] border-[2px] border-[#1369E9] transition  focus:outline-none focus:ring focus:ring-yellow-400"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
  
              <img
                alt="Student"
                src="https://www.themetechmount.com/react/hireco/images/bg-image/col-bgimage-3.jpg"
                className="h-56 w-full object-cover rounded-[12px] sm:h-full"
              />
            </section>
          </div>
        </div>
    </div>
  );
}
