import React from 'react'

export default function Partnership() {
  return (
    <section className="mt-24 mb-24 mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
    <div className="text-center space-y-4">
        <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
            Partnership
             
        </h1>

    </div>
</section>
  )
}
