import React from "react";
import { useNavigate } from "react-router";

export default function Webinar() {
  const testimonials = [
    {
      avatar: "images/ManjeetProfile.png",
      name: "Manjeet Singh",
      title: "CPO & GTM",
      quote: "How it Works,Why You Need it, and Key Strategies for Success",
    },

  ];

  const nav =useNavigate()
  return (
    <>
      <section className="py-20 bg-[#F3F5F9]">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="max-w-xl sm:text-center md:mx-auto">
            <h3 className="text-gray-800  text-3xl font-semibold sm:text-4xl">
              Webinars
            </h3>
            {/* <p className="mt-3 text-gray-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et est hendrerit, porta nunc vitae, gravida justo. Nunc fermentum magna lorem, euismod volutpat arcu volutpat et.
                    </p> */}
          </div>
          <div className=" flex justify-center">
            {/* <ul className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 "> */}
            <ul className="flex  py-20 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-1 ">
              {testimonials.map((item, idx) => (
                <li key={idx} className="bg-[#FFF] flex flex-col items-center justify-center p-4 xl:p-12 rounded-[12px] shadow-CardsShadow">
                  <figure className="flex flex-col" >
                    <div className="flex  flex-col justify-center items-center sm:flex-row xl:flex-row md:flex-row lg:flex-row  gap-x-4">
                      <img
                        src={item.avatar}
                        className="w-16 h-16 rounded-full"
                      />
                      <div>
                        <span className="block text-gray-800 font-semibold">
                          {item.name}
                        </span>
                        <span className="block text-gray-600 text-sm mt-0.5">
                          {item.title}
                        </span>
                      </div>
                    </div>
                    <blockquote>
                      <p className="mt-6 text-gray-700">{item.quote}</p>
                    </blockquote>
                  </figure>
                  <button
                  onClick={e=>{nav("/contact?webinar")}}
                    
                    className="block  w-full md:w-auto lg:w-auto py-2 px-4 mt-5   text-center text-white font-medium bg-blue-600 duration-150 hover:bg-blue-500 active:bg-blue-700 rounded-lg shadow-lg hover:shadow-none"
                  >
                    SignUp for Free
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
