import React from 'react'

export default function CaseStudy() {
  return (

    <>
        <section className="mt-24 mb-24 mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
                <div className="text-center space-y-4">
                    <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
                        Case
                         <span className="text-indigo-600"> Study</span>
                    </h1>
                </div>
            </section>
    </>
  )
}
