import React from 'react'

function TermsConditions() {
  return (
    <div className='flex justify-center bg-[#F3F5F9]'>
    <div className="flex items-center    max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[70px]">
    <div class="">
    <h1 class="text-3xl font-bold text-center my-8">Terms of Use</h1>    
    <div class="my-4 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]" >Introduction</h2>
      <p>
        Welcome to SproutsAI Inc. (“Company”, “we”, “our”, “us”)! As you have
        just clicked our Terms of Service, please carefully read the following
        pages. It will take you approximately 20 minutes.
      </p>

      <p>
        These Terms of Service (“Terms”, “Terms of Service”) govern your use of
        our web pages located at https://www.sproutsai.com operated by SproutsAI
        Inc.
      </p>

      <p>
        Our Privacy Policy also governs your use of our Service and explains how
        we collect, safeguard and disclose information that results from your
        use of our web pages. Please read it here.
      </p>

      <p>
        Your agreement with us includes these Terms and our Privacy Policy
        (“Agreements”). You acknowledge that you have read and understood
        Agreements, and agree to be bound of them.
      </p>

      If you do not agree with (or cannot comply with) Agreements, then you may
      not use the Service, but please let us know by emailing at
      support@sproutsai.com so we can try to find a solution. These Terms apply
      to all visitors, users and others who wish to access or use Service.

      <p>Thank you for being responsible.</p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Communications</h2>
      <p>
        By creating an Account on our Service, you agree to subscribe to
        newsletters, marketing or promotional materials and other information we
        may send. However, you may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or by emailing
        at support@sproutsai.com
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Purchases</h2>
      <p>
        If you wish to purchase any product or service made available through
        Service (“Purchase”), you may be asked to supply certain information
        relevant to your Purchase including, without limitation, your credit
        card number, the expiration date of your credit card, your billing
        address, and your shipping information.
      </p>

      <p>
        You represent and warrant that: (i) you have the legal right to use any
        credit card(s) or other payment method(s) in connection with any
        Purchase; and that (ii) the information you supply to us is true,
        correct and complete.
      </p>

      <p>
        We may employ the use of third party services for the purpose of
        facilitating payment and the completion of Purchases. By submitting your
        information, you grant us the right to provide the information to these
        third parties subject to our Privacy Policy.
      </p>

      <p>
        We reserve the right to refuse or cancel your order at any time for
        reasons including but not limited to: product or service availability,
        errors in the description or price of the product or service, error in
        your order or other reasons.
      </p>

      <p>
        We reserve the right to refuse or cancel your order if fraud or an
        unauthorized or illegal transaction is suspected.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Subscriptions</h2>
      <p>
        Some parts of Service are billed on a subscription basis
        (“Subscription(s)”). You will be billed in advance on a recurring and
        periodic basis (“Billing Cycle”). Billing cycles are custom for each
        account.
      </p>

      <p>
        At the end of each Billing Cycle, your Subscription will automatically
        renew under the exact same conditions unless you cancel it or SproutsAI
        Inc. cancels it. You may cancel your Subscription renewal either through
        your online account management page or by contacting SproutsAI Inc.
        customer support team.
      </p>

      <p>
        A valid payment method, including credit card, is required to process
        the payment for your subscription. You shall provide SproutsAI Inc. with
        accurate and complete billing information including full name, address,
        state, zip code, telephone number, and a valid payment method
        information. By submitting such payment information, you automatically
        authorize SproutsAI Inc. to charge all Subscription fees incurred
        through your account to any such payment instruments.
      </p>

      <p>
        Should automatic billing fail to occur for any reason, SproutsAI Inc.
        will issue an electronic invoice indicating that you must proceed
        manually, within a certain deadline date, with the full payment
        corresponding to the billing period as indicated on the invoice.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Fee Changes</h2>
      <p>
        SproutsAI Inc., in its sole discretion and at any time, may modify
        Subscription fees for the Subscriptions. Any Subscription fee change
        will become effective at the end of the then-current Billing Cycle.
      </p>
      <p>
        SproutsAI Inc. will provide you with a reasonable prior notice of any
        change in Subscription fees to give you an opportunity to terminate your
        Subscription before such change becomes effective.
      </p>
      <p>
        Your continued use of Service after Subscription fee change comes into
        effect constitutes your agreement to pay the modified Subscription fee
        amount. ‍‍
      </p>
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Refunds</h2>
      <p>
        Except when required by law, paid Subscription fees are non-refundable.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Prohibited Uses</h2>
      <p>
        You may use Service only for lawful purposes and in accordance with
        Terms. You agree not to use Service:
      </p>
      <ol>
        <li>
          In any way that violates any applicable national or international law
          or regulation.
        </li>
        <li>
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content or
          otherwise.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional
          material, including any “junk mail”, “chain letter,” “spam,” or any
          other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate Company, a Company employee,
          another user, or any other person or entity.
        </li>
        <li>
          In any way that infringes upon the rights of others, or in any way is
          illegal, threatening, fraudulent, or harmful, or in connection with
          any unlawful, illegal, fraudulent, or harmful purpose or activity.
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits anyone’s use
          or enjoyment of Service, or which, as determined by us, may harm or
          offend Company or users of Service or expose them to liability.
        </li>
      </ol>
      <p>Additionally, you agree not to:</p>
      <ol>
        <li>
          Use Service in any manner that could disable, overburden, damage, or
          impair Service or interfere with any other party’s use of Service,
          including their ability to engage in real time activities through
          Service.
        </li>
        <li>
          Use any robot, spider, or other automatic device, process, or means to
          access the non API portions of Service for any purpose, including
          monitoring or copying any of the material on Service.
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on
          Service or for any other unauthorized purpose without our prior
          written consent.
        </li>
        <li>
          Use any device, software, or routine that interferes with the proper
          working of Service.
        </li>
        <li>
          Introduce any viruses, trojan horses, worms, logic bombs, or other
          material which is malicious or technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of Service, the server on which Service is stored,
          or any server, computer, or database connected to Service.
        </li>
        <li>
          Attack Service via a denial-of-service attack or a distributed
          denial-of-service attack.
        </li>
        <li>Take any action that may damage or falsify Company rating.</li>
        <li>
          Otherwise attempt to interfere with the proper working of Service.
        </li>
      </ol>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Analytics</h2>
      <p>
        We may use third-party Service Providers to monitor and analyze the use
        of our Service.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">No Use By Minors</h2>
      <p>
        Service is intended only for access and use by individuals at least
        eighteen (18) years old. By accessing or using any of Company, you
        warrant and represent that you are at least eighteen (18) years of age
        and with the full authority, right, and capacity to enter into this
        agreement and abide by all of the terms and conditions of Terms. If you
        are not at least eighteen (18) years old, you are prohibited from both
        the access and usage of Service.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Accounts</h2>
      <p>
        When you create an account with us, you guarantee that you are above the
        age of 18, and that the information you provide us is accurate,
        complete, and current at all times. Inaccurate, incomplete, or obsolete
        information may result in the immediate termination of your account on
        Service.
      </p>
      <p>
        You are responsible for maintaining the confidentiality of your account
        and password, including but not limited to the restriction of access to
        your computer and/or account. You agree to accept responsibility for any
        and all activities or actions that occur under your account and/or
        password, whether your password is with our Service or a third-party
        service. You must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of your account.
      </p>
      <p>
        You may not use as a username the name of another person or entity or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than you,
        without appropriate authorization. You may not use as a username any
        name that is offensive, vulgar or obscene.
      </p>
      <p>
        We reserve the right to refuse service, terminate accounts, remove or
        edit content, or cancel orders in our sole discretion.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Intellectual Property</h2>
      <p>
        Service and its original content (excluding Content provided by users),
        features and functionality are and will remain the exclusive property of
        SproutsAI Inc. and its licensors. Service is protected by copyright,
        trademark, and other laws of the United States. Our trademarks and trade
        dress may not be used in connection with any product or service without
        the prior written consent of SproutsAI Inc.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Error Reporting and Feedback</h2>
      <p>
        You may provide us either directly at support@sproutsai.com or via third
        party sites and tools with information and feedback concerning errors,
        suggestions for improvements, ideas, problems, complaints, and other
        matters related to our Service (“Feedback”). You acknowledge and agree
        that: (i) you shall not retain, acquire or assert any intellectual
        property right or other right, title or interest in or to the Feedback;
        (ii) Company may have development ideas similar to the Feedback; (iii)
        Feedback does not contain confidential information or proprietary
        information from you or any third party; and (iv) Company is not under
        any obligation of confidentiality with respect to the Feedback. In the
        event the transfer of the ownership to the Feedback is not possible due
        to applicable mandatory laws, you grant Company and its affiliates an
        exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
        unlimited and perpetual right to use (including copy, modify, create
        derivative works, publish, distribute and commercialize) Feedback in any
        manner and for any purpose.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third party web sites or services that
        are not owned or controlled by SproutsAI Inc.
      </p>
      <p>
        SproutsAI Inc. has no control over, and assumes no responsibility for
        the content, privacy policies, or practices of any third party web sites
        or services. We do not warrant the offerings of any of these
        entities/individuals or their websites.
      </p>
      <p>
        YOU ACKNOWLEDGE AND AGREE THAT SPROUTSAI INC. SHALL NOT BE RESPONSIBLE
        OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
        ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY
        SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD
        PARTY WEB SITES OR SERVICES.
      </p>
      <p>
        WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES
        OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Disclaimer Of Warranty</h2>
      <p>
        THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
        EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE
        INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
        THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR
        ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
      </p>
      <p>
        NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
        WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT
        LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH
        COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY
        SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE,
        RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
        THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
        VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
        OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
        EXPECTATIONS.
      </p>
      <p>
        COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
        IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
        WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
        PARTICULAR PURPOSE.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Limitation Of Liability</h2>
      <p>
        EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
        DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
        (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF
        LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR
        NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF
        CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
        CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
        FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND
        ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES,
        RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
        LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT
        PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
        THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
        EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Termination</h2>
      <p>
        We may terminate or suspend your account and bar access to Service
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of Terms.
      </p>
      <p>
        If you wish to terminate your account, you may simply discontinue using
        Service.
      </p>
      <p>
        All provisions of Terms which by their nature should survive termination
        shall survive termination, including, without limitation, ownership
        provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>

      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of State of California without regard to its conflict of law provisions.
      </p>
      <p>
        Our failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service and supersede and replace any
        prior agreements we might have had between us regarding Service.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Changes To Service</h2>
      <p>
        We reserve the right to withdraw or amend our Service, and any service
        or material we provide via Service, in our sole discretion without
        notice. We will not be liable if for any reason all or any part of
        Service is unavailable at any time or for any period. From time to time,
        we may restrict access to some parts of Service, or the entire Service,
        to users, including registered users.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Amendments To Terms</h2>
      <p>
        We may amend Terms at any time by posting the amended terms on this
        site. It is your responsibility to review these Terms periodically.
      </p>
      <p>
        Your continued use of the Platform following the posting of revised
        Terms means that you accept and agree to the changes. You are expected
        to check this page frequently so you are aware of any changes, as they
        are binding on you.
      </p>
      <p>
        By continuing to access or use our Service after any revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, you are no longer authorized to use Service.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Waiver And Severability</h2>
      <p>
        No waiver by Company of any term or condition set forth in Terms shall
        be deemed a further or continuing waiver of such term or condition or a
        waiver of any other term or condition, and any failure of Company to
        assert a right or provision under Terms shall not constitute a waiver of
        such right or provision.
      </p>
      <p>
        If any provision of Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal or unenforceable for any
        reason, such provision shall be eliminated or limited to the minimum
        extent such that the remaining provisions of Terms will continue in full
        force and effect.
      </p>
      ‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Acknowledgement</h2>
      <p>
        BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT
        YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
      </p>
      ‍‍
    </div>
    <div className="mb-6 border-l-4 border-blue-400 pl-4">
     <h2 className="text-xl font-semibold text-[#66708]">Questions, concerns or complaints</h2>
      <p>
        If you have questions, concerns, complaints, or would like to exercise
        your rights, please contact us at:
      </p>
      <h5>SproutsAI Inc.</h5>
      <h5>Niraj Rai</h5>
      <h5>contacts@sproutsai.com</h5>
      <h5>+1-408-799-8605</h5>
      ‍
    </div>
    </div>
    </div>
    </div>
  )
}

export default TermsConditions