import React, { useState } from 'react';
import Subscribe from './Subscribe';
import HomepageLogo from '../components/assets/HomepageLogo.png'
import { useInView } from "react-intersection-observer";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router';

export default function NoCodeBuilder() {
    const nav = useNavigate()
    const slides = [
        'http://dogsdiscovered.com/wp-content/uploads/2011/03/growling-dog.jpg',
        'https://pbs.twimg.com/profile_images/498611745100156928/1x9OADbM_400x400.jpeg',
        'https://pbs.twimg.com/profile_images/473535319090819074/6j-F-_N-_400x400.jpeg',
        'https://cdn2.bigcommerce.com/server3800/b0iqeya/product_images/uploaded_images/wet-dog.png?t=1409100694',
        'https://4.bp.blogspot.com/_4vn24YA-Rc4/Sm6yCChb-PI/AAAAAAAAA8Q/gS5WgIYb754/s400/dogs+color+vision.PNG',
    ];

    const [activeSlide, setActiveSlide] = useState(0);

    const nextSlide = () => {
        setActiveSlide((activeSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };




    function fetchPublicJobs() {
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
        };
        const body = {
            "firstname": "Pankaj",
            "lastname": "Chouhan",
            "name": "Pankaj Chouhan",
            "phone": "8770631461",
            "message": "My name is pankaj",
            "forReq": "demo",
            "subject": "Demo Request - SproutsAI",
            "email": "pankaj@sproutsai.com",
            "to": "pankaj@sproutsai.com"
        }

        fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
            .then(response => response.json())
            .then(result => console.log())
            .catch(error => console.log('error', error));
    }

    const [ref1, inView1] = useInView({
        triggerOnce: false, // Only trigger once
    });
    const [ref2, inView2] = useInView({
        triggerOnce: false, // Only trigger once
    });
    const [ref3, inView3] = useInView({
        triggerOnce: false, // Only trigger once
    });
    const [ref4, inView4] = useInView({
        triggerOnce: false, // Only trigger once
    });
    const [ref5, inView5] = useInView({
        triggerOnce: false, // Only trigger once
    });
    const [ref6, inView6] = useInView({
        triggerOnce: false, // Only trigger once
    });




    const CompanyImages = [

        "images/Vectors-Wrapper_3.svg",
        "images/Vectors-Wrapper_4.svg",
        "images/Vectors-Wrapper_5.svg",
        "images/Vectors-Wrapper_6.svg",
        "images/Vectors-Wrapper_7.svg",
        "images/Vectors-Wrapper_8.svg",
        "images/Vectors-Wrapper_9.svg",
        "images/Vectors-Wrapper_10.svg",
        "images/AlphaNumero.svg",
        "images/GlobalFair.svg",
        "images/NiralNetwork.svg",
        "images/Yatra.svg"

    ]

    const hirinfText = [
        { heading: "Requirement Discussion", text: "Thorough discussion on Job roles and defining ideal candidates", img: "images/1.png" },
        { heading: "Market Research and analysis", text: "Our Data-driven hiring provide real time data on market trend, salary, competitor analysis", img: "images/2.png" },
        { heading: "Sourcing/Shortlisting", text: "Faster sourcing capabilities throughour AI tools.", img: "images/3.png" },
        { heading: "SproutsAI Interview", text: "We gauge the candidate on 3 parameters: Productivity, Value Addition, Culture.", img: "images/4.png" },
        { heading: "Client Interviews", text: "We strive to facilitate meaningful interactions that lead to informed hiring decisions", img: "images/5.png" },
        { heading: "Onboarding and Documentation", text: "We take care of follow ups and provide support in background checking", img: "images/6.png" },
    ]

    const [activeTab, setActiveTab] = useState(1)
    const [qsnTab, setqsnTab] = useState(1)

    const faqsList = [
        {
            q: "Executive Search",
            a: <ul role="list" className="list-none flex flex-wrap">
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/user-circle.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Identify and recruit top-level executives for
                        leadership positions
                    </div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/search-normal.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Extensive market research and candidate mapping
                    </div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/donate-coin.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>End to End recruitment</div>
                </li>
            </ul>,
        },
        {
            q: "Engineering Hiring",
            a: <ul role="list" className="list-none flex flex-wrap">
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/video.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Source and screen highly skilled technical
                        professionals
                    </div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/mobile-programming.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Assess technical expertise and problem-solving
                        abilities
                    </div>
                </li>
                <li className="flex items-center w-[50%] hide">
                    <img className="mr-[12px]"
                        src="images/donate-coin.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>End to End recruitment</div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/brifecase-timer.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>Dedicated recruiter and account manager</div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/Logo---symbol-2.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Subscription to our inhouse AI recruitment platform
                    </div>
                </li>
            </ul>
            ,
        },
        {
            q: "Recruitment Process Outsourcing",
            a: <ul role="list" className="list-none flex flex-wrap">
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/programming-arrow.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div className="text-block-6">
                        We offer end-to-end RPO solutions tailored to your
                        organization's specific needs
                    </div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/pen-tool-2-1.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div className="text-block-8">
                        Our comprehensive recruitment services eliminate the
                        need for additional hiring tools
                    </div>
                </li>
                <li className="flex items-center w-[50%] hide">
                    <img className="mr-[12px]"
                        src="images/donate-coin.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>End to End recruitment</div>
                </li>
                <li className="flex items-center w-[50%] hide">
                    <img className="mr-[12px]" src="images/setting.svg" loading="lazy" alt="" />
                    <div className="text-block-7">
                        Our RPO services are designed to accommodate your
                        changing staffing requirements
                    </div>
                </li>
                <li className="flex items-center w-[50%] hide">
                    <img className="mr-[12px]"
                        src="images/Logo---symbol-2.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Subscription to our inhouse AI recruitment platform
                    </div>
                </li>
            </ul>
            ,
        },
        {
            q: "Other search",
            a: <ul role="list" className="list-none flex flex-wrap">
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/chart-arrow-up-01.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div className="text-block-6">
                        Quickly scale your workforce with temporary or
                        contract employees
                    </div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]"
                        src="images/pen-tool-2-1.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div className="text-block-8">
                        Our comprehensive recruitment services eliminate the
                        need for additional hiring tools
                    </div>
                </li>
                <li className="flex items-center w-[50%] hide">
                    <img className="mr-[12px]"
                        src="images/donate-coin.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>End to End recruitment</div>
                </li>
                <li className="flex items-center w-[50%]">
                    <img className="mr-[12px]" src="images/setting.svg" loading="lazy" alt="" />
                    <div className="text-block-7">
                        We actively cater to hiring needs in the
                        &nbsp;finance/banking/investment/healthcare domain.
                    </div>
                </li>
                <li className="flex items-center w-[50%] hide">
                    <img className="mr-[12px]"
                        src="images/Logo---symbol-2.svg"
                        loading="lazy"
                        alt=""
                    />
                    <div>
                        Subscription to our inhouse AI recruitment platform
                    </div>
                </li>
            </ul>
            ,
        },
    ];



    const qnalist = [
        {
            q: "Why is SproutsAI priced per hiring and not an annual subscription?",
            a: "Sprouts AI uses consumption-based pricing. You only pay for what you use. This way, you’re not paying for excess capacity or unused functionality. Our consumption-based pricing is modeled after tech leaders like Snowflake and AWS."
        },
        {
            q: "Can we purchase more candidate bundles during the current license period?",
            a: "Yes, you can always purchase additional hiring bundle credits during the year. However, if you anticipate needing significantly more conversation credits, it’s best to purchase those up front to secure the deepest volume discount."
        },
        {
            q: "Will we receive monthly invoices?",
            a: "Yes. Even though you’re purchasing it up-front, you’ll receive a monthly invoice with the number of hiring credits used, the cost associated with those used credits, and the number of credits remaining in your account."
        },
        {
            q: "What ATS provider the solution integrate with?",
            a: "We currently integrate with Greenhouse, Lever, Ashby, and Workable. If you have requests for other ATS providers, please let us know at talent@sproutsai.com"
        }
    ]

    const [hiringBlog, setHiringBlog] = useState(0)


    const executiveSearch = [
        { name: "Executive Search", price: "499", imagesText: [{ img: "images/user-circle.svg", text: "Identify and recruit top-level executives for leadership positions" }, { img: "images/search-normal.svg", text: "Extensive market research and candidate mapping" }, { img: "images/donate-coin.svg", text: "End to End recruitmen" }, { img: "images/brifecase-timer.svg", text: "Dedicated recruiter and account manager" }, { img: "images/Logo---symbol-2.svg", text: "Subscription to our inhouse AI recruitment platform" }] },
        { name: "Engineering Hiring", price: "799", imagesText: [{ img: "images/video.svg", text: " Source and screen highly skilled technical professionals" }, { img: "images/mobile-programming.svg", text: "  Assess technical expertise and problem-solving abilities" }, { img: "images/donate-coin.svg", text: "End to End recruitment" }, { img: "images/brifecase-timer.svg", text: "Dedicated recruiter and account manager" }, { img: "images/Logo---symbol-2.svg", text: "Subscription to our inhouse AI recruitment platform" }] },
        { name: "Recruitment Process Outsourcing", price: "899", imagesText: [{ img: "images/programming-arrow.svg", text: "  We offer end-to-end RPO solutions tailored to your organization's specific needs" }, { img: "images/pen-tool-2-1.svg", text: "     Our comprehensive recruitment services eliminate the need for additional hiring tools" }, { img: "images/donate-coin.svg", text: "End to End recruitment" }, { img: "images/setting.svg", text: "   Our RPO services are designed to accommodate your changing staffing requirements" }, { img: "images/Logo---symbol-2.svg", text: "  Subscription to our inhouse AI recruitment platform" }] },
        { name: "Other search", price: "699", imagesText: [{ img: "images/chart-arrow-up-01.svg", text: "  Quickly scale your workforce with temporary or contract employees" }, { img: "images/pen-tool-2-1.svg", text: "Our comprehensive recruitment services eliminate the need for additional hiring tools" }, { img: "images/donate-coin.svg", text: "End to End recruitment" }, { img: "images/setting.svg", text: "We actively cater to hiring needs in the &nbsp;finance/banking/investment/healthcare domain." }, { img: "images/Logo---symbol-2.svg", text: "Subscription to our inhouse AI recruitment platform" }] }
    ]
    return (
        <div className="">
            <div className="relative h-[30vh]">
                <img
                    src="https://www.themetechmount.com/react/hireco/images/pagetitle-bg.jpg"
                    className="absolute inset-0 object-cover w-full h-full"
                    alt=""
                />
                <div className="relative bg-gray-900 h-full bg-opacity-70">
                    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="flex flex-col items-center justify-between ">
                            <h1 className='text-[#FFF] text-[32px] not-italic font-semibold'>Executive search</h1>

                        </div>
                    </div>
                </div>
            </div>


            <div className={`flex flex-col items-center  bg-gray-50 `}>
                <div className="flex flex-col       max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
                    <section className="overflow-hidden  sm:grid sm:grid-cols-2">
                        <div className="  lg:py-24">
                            <div
                                className="mx-auto max-w-xl  ltr:sm:text-left rtl:sm:text-right"
                            >
                                <span className="text-[#16182F] font-normal text-[14px] leading-[1px] text-center">Looking for <span className='text-[#1369E9]'>an expert!</span> </span>
                                <h2 className="text-2xl font-bold text-gray-900 md:text-3xl mt-[10px]">
                                    Executive search
                                </h2>

                                <p className="hidden text-gray-500 md:mt-4 md:block">
                                    We, Sproutium specializes in recruiting  executives and other personnel for their client company
                                </p>

                                <div className="mt-4 md:mt-8">
                                    <a
                                        href="#"
                                        className="inline-block rounded  px-12 py-3 text-sm font-medium text-[#1369E9] border-[2px] border-[#1369E9] transition  focus:outline-none focus:ring focus:ring-yellow-400"
                                    >
                                        Find talents
                                    </a>
                                </div>
                            </div>
                        </div>

                        <img
                            alt="Student"
                            src="https://www.themetechmount.com/react/hireco/images/services/services-05.jpg"
                            className="h-[400px] rounded-[12px] w-full object-cover "
                        />
                    </section>
                </div>
            </div>



            <div className={` flex justify-center w-full  `}>
                <div className="flex flex-col    w-full   max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">

                    {executiveSearch.map((item, idx) => (
                        <div className='mt-[40px]  rounded-[16px] p-[30px]    bg-gray-50 '>
                            <div className='flex flex-col'>
                                <div className='flex justify-between'>
                                    <h1 className='text-[20px] font-semibold not-italic '>{item.name}</h1>
                                    <div>
                                        <span className="text-span-2 text-[20px] font-semibold not-italic ">${item.price}/month</span>
                                        <span className='ml-[40px] text-[20px] font-semibold not-italic'>+</span>
                                    </div>
                                </div>

                                <div className="mt-[20px]">
                                    <div className="text-block-3">What included?</div>
                                    <div className='mt-[20px] grid grid-cols-2 gap-[20px]'>
                                        {item.imagesText.map((iter, id) => (
                                            <li className='flex items-center'>
                                                <img
                                                    src={iter?.img}
                                                    loading="lazy"
                                                    alt=""
                                                    className="w-[25px] mr-[14px] "
                                                />
                                                {iter?.text}
                                            </li>
                                        ))}
                                    </div>
                                </div>

                            </div>

                            <a
                                href="/ComingSoon"
                                className="h-[40px] w-[200px] bg-[#1369E9] flex justify-center items-center  rounded-[12px] text-[#FFF] mt-[20px]"
                            >
                                <img src="images/call-icon.svg" loading="lazy" alt="" className='mr-[10px]' />
                                Get in touch
                            </a>
                        </div>
                    ))}

                </div>
            </div>


            <div className={`flex flex-col items-center  bg-gray-50 `}>
                <div className="flex flex-col       max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
                    <section className="overflow-hidden  sm:grid sm:grid-cols-2">
                        <div className="  lg:py-24">
                            <div
                                className="mx-auto max-w-xl  ltr:sm:text-left rtl:sm:text-right"
                            >
                                <span className="text-[#16182F] font-normal text-[14px] leading-[1px] text-center">Looking for <span className='text-[#1369E9]'>an expert!</span> </span>
                                <h2 className="text-2xl font-bold text-gray-900 md:text-3xl mt-[10px]">
                                    Retained search
                                </h2>

                                <p className="hidden text-gray-500 md:mt-4 md:block">
                                Sproutium tens to accelerate innovation current and future based solutions to support customers.
                                </p>

                                <div className="mt-4 md:mt-8">
                                    <a
                                        href="#"
                                        className="inline-block rounded  px-12 py-3 text-sm font-medium text-[#1369E9] border-[2px] border-[#1369E9] transition  focus:outline-none focus:ring focus:ring-yellow-400"
                                    >
                                        Find talents
                                    </a>
                                </div>
                            </div>
                        </div>

                        <img
                            alt="Student"
                            src="https://www.themetechmount.com/react/hireco/images/services/services-06.jpg"
                            className="h-[400px] rounded-[12px] w-full object-cover "
                        />
                    </section>
                </div>
            </div>

            <div className="max-w-screen-xl mx-auto md:px-8 py-[100px]">
                <div className="relative font-sans ">
                    <div className='mb-[30px]'>
                        <h1 className='text-center text-[#16182F] font-semibold text-[28px] leading-[40px] '>Questions & Answers</h1>
                    </div>
                    {qnalist.map((item, idx) => (
                        <div className='border-b border-b-[rgba(0,0,0,.06)] py-[20px] cursor-pointer' onClick={e => setqsnTab(idx)}>
                            <div className='flex justify-between'>
                                <h1 className='text-[18px] font-medium not-italic'>{item.q}</h1>
                                {qsnTab === idx ? (
                                    <span className='rounded-[50%]  text-[30px] font-bold'>-</span>
                                ) : (
                                    <span className='text-[30px] font-bold'>+</span>
                                )}
                            </div>
                            {qsnTab === idx &&
                                <div className='w-[70%] mt-[10px]'>
                                    {item?.a}

                                </div>
                            }
                        </div>
                    ))}
                    <div className='text-center mt-[30px]'>
                        <span className='bg-[#f1f6f3] rounded-[30px] py-[3px] px-[25px] text-[16px]'>Don’t find the answer? We can help. <a href="" className='text-[#1369E9]'>Click here</a> </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
