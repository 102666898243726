import { createAsyncThunk } from '@reduxjs/toolkit';
const core = require("../../lib/core");
// Async thunk action creators

export const adminLoginService = createAsyncThunk(
    'admin/adminlogin',
    async (payload, thunkAPI) => {
       return await core.API(
            core.API_METHODS.POST,
            core.ADMIN_LOGIN_API.ADMIN_TOKEN,
            1,
            payload
        );
        // Async logic for validate ats token
    }
);
