import React from 'react'

export default function CustomerServiceAutomationPage() {
    return (
        <>
        <section class="bg-white dark:bg-gray-900">
        <div class="container flex flex-col items-center px-4 py-12 mx-auto text-center">
            <h2 class="max-w-2xl mx-auto text-2xl font-semibold tracking-tight text-gray-800 xl:text-5xl dark:text-white">
            Customer <span class="text-blue-500">Service</span>
            </h2>
    
        </div>
    </section>
    
    <section>
      <div
        class="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8"
      >
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div
            class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
          >
            <img
              alt="Party"
              src="images/about1.png"
              class="absolute inset-0 h-full w-full object-cover"
            />
          </div>
    
          <div class="lg:py-24">
            <h2 class="text-3xl font-bold sm:text-4xl">Automate and Personalize Customer Support</h2>
    
            <p class="mt-4 text-gray-600">
            SproutsAI's AI-powered autonomous agents can help you provide 24/7 customer support. Our agents can answer customer questions, resolve issues, and provide support to your customers in their preferred language.
    
            </p>
    
            <div className='mt-5 mb-5'>
                        <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">Key Features</h1>
    
                        <div class="mt-2">
                            <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                            <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                            <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        </div>
                    </div>
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
            <a
              className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
              href="/accountant"
            >
              <h2 className="mt-2 font-bold">Natural conversations</h2>
            </a>
    
            <a
              className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
              href="/accountant"
            >
              <h2 className="mt-2 font-bold">Query deflection</h2>
            </a>
            <a
              className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
              href="/accountant"
            >
              <h2 className="mt-2 font-bold">Sentiment analysis</h2>
            </a>
            <a
              className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
              href="/accountant"
            >
              <h2 className="mt-2 font-bold">Escalation to human agents</h2>
            </a>
    
          </div>
    
          </div>
        </div>
      </div>
    </section>
    
    
    
    
    
        </>
      )
}
