import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { adminLoginService } from '../service/adminLoginService';

const core =require("../../lib/core")

const initialState = {
  isLoading: true,
  error:null,
  loginData:null 
};



const adminLoginSlice = createSlice({
  name: 'adminLogin',
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
    .addCase(adminLoginService.pending, (state, action) => {
      state.isLoading = true
      state.loginData = null;
    })
      .addCase(adminLoginService.fulfilled, (state, action) => {
        state.loginData = action.payload
        state.isLoading = false;
      })
      .addCase(adminLoginService.rejected, (state, action) => {
        state.isLoading = null;
        state.loginData = false;
      })
  },
});

// Export actions
export const { } = adminLoginSlice.actions;

export default adminLoginSlice.reducer;
