import React, { useEffect, useState } from "react";

export default function Career() {
  const JobOpening = ({ position, department, location,id,jobType }) => {
    return (
      <>
 
        <div className="relative flex flex-col jus items-center justify-center overflow-hidden bg-gray-50 p-6 sm:py-8">
          <div className="bg-white  shadow-xl shadow-gray-100 w-full max-w-4xl flex flex-col sm:flex-row gap-3 sm:items-center  justify-between px-5 py-4 rounded-md">
            <div>
              <span className="text-blue-600 text-sm">{department?.join(", ")}</span>
              <h3 className="font-bold mt-px">{position}</h3>
              <div className="flex items-center gap-3 mt-2">
                <span className="bg-blue-100 text-blue-600 rounded-full px-3 py-1 text-sm">
                  {jobType && jobType?.length ? jobType?.filter(x=>x?.status==true):""}
                </span>
                <span className="text-slate-600 text-sm flex gap-1 items-center ">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>{" "}
                  {location}
                </span>
              </div>
            </div>
            <div className="bg-red-500">
              <button className="bg-blue-600 text-white rounded  font-medium px-4 py-2 rounded-md flex gap-1 items-center">
                Apply Now{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13 7l5 5m0 0l-5 5m5-5H6"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </>

    );
  };
 

  const [jobList, setJobList] = useState([])
  function fetchPublicJobs() {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch("http://localhost:3333/recruiter/get-public-jobs", requestOptions)
      .then(response => response.json())
      .then(result => setJobList(result?.data?.jobs))
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    fetchPublicJobs()
  }, [])

  console.log(jobList)
  return (
    <div className="bg-[#F3F5F9] py-20">
      <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
        <div className="text-center space-y-4">
          <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
            Job
            <span className="text-blue-700"> Openings</span>
          </h1>
        </div>

        <div className="text-center space-y-4 flex justify-center mt-20">
          <div className="bg-[#FFF] w-[400px] flex flex-col justify-center py-[40px] shadow-CardsShadow rounded-[12px] items-center">
          <h1 className="text-gray-800  font-bold text-2xl md:text-2xl">
            We are hiring 
          </h1>
          <span className="mt-[10px]">Reach out to "<span className="text-blue-700">careers@sproutsai.com</span>" </span>
          
          </div>
        </div>


      </section>
      <div className="container bg-[#FFFFFF]  mx-auto ">
        {/* <h1 className="text-3xl font-semibold mb-4">Job Openings</h1> */}
        <div className="">
          {jobList?.map((opening, index) => (
            <div key={index} className="">
              <JobOpening
                position={opening?.position}
                department={opening?.department}
                location={opening?.workplace}
                id = {opening?.id}
                jobType= {opening?.jobType}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}