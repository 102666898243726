import React from 'react';

function Cookies() {
  return (
    <div className="bg-[#F3F5F9] min-h-screen flex justify-center items-center">
      <div className="max-w-screen-xl px-4 sm:px-6 lg:px-8 py-10">
        <div className="font-sans bg-gray-100  rounded-lg">
          <h1 className="text-3xl font-bold text-center my-8">Cookie Policy</h1>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">Application</h2>
            <p>
              This policy applies to all employees, contractors, and vendors while
              doing business with SproutsAI Inc. and others who have access to
              European Union (EU) and the European Economic Area (EEA) data subject
              information (“personal data”) in connection with SproutsAI Inc.’s
              operating activities.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">Policy</h2>
            <p>
              SproutsAI Inc. believes in transparency about collection and use of
              data. This policy provides information about how and when SproutsAI Inc.
              uses cookies for these purposes. Capitalized terms used in this policy
              but not defined have the meaning set forth in our Privacy Policy, which
              also includes additional details about the collection and use of
              information at SproutsAI Inc.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">What is a cookie?</h2>
            <p>
              Cookies are small text files sent by us to your computer or mobile
              device, which enable SproutsAI Inc. features and functionality. They are
              unique to your account or your browser. Session-based cookies last only
              while your browser is open and are automatically deleted when you close
              your browser. Persistent cookies last until you or your browser delete
              them or until they expire.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">Does SproutsAI Inc. use cookies?</h2>
            <p>
              Yes. SproutsAI Inc. uses cookies and similar technologies like
              single-pixel gifs and web beacons. SproutsAI Inc. uses both
              session-based and persistent cookies. SproutsAI Inc. sets and accesses
              cookies on the domains operated by SproutsAI Inc. and its corporate
              affiliates (collectively, the “Sites”). In addition, SproutsAI Inc. uses
              third party cookies, like Google Analytics.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">How is SproutsAI Inc. using cookies?</h2>
            <p>
              Some cookies are associated with your account and personal information
              to remember that you are logged in and which workspaces you are logged
              into. Other cookies are not tied to your account but are unique and
              allow us to carry out analytics and customization, among other similar
              things.
            </p>
            <p>
              Cookies can be used to recognize you when you visit a Site or use our
              Services, remember your preferences, and give you a personalized
              experience that is consistent with your settings. Cookies also make your
              interactions faster and more secure.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">Categories of use</h2>
            <ul>
              <li>
                <h3 className="text-lg font-semibold">Authentication:</h3>
                If you're signed into the Services, cookies help SproutsAI Inc. show
                you the right information and personalize your experience.
              </li>
              <li>
                <h3 className="text-lg font-semibold">Security:</h3>
                SproutsAI Inc. uses cookies to enable and support security features,
                and to help detect malicious activity.
              </li>
              <li>
                <h3 className="text-lg font-semibold">Preferences, features, and services:</h3>
                Cookies denote which language you prefer and what your communications
                preferences are. They can help fill out forms on our Sites more
                easily. They also provide you with features, insights, and customized
                content.
              </li>
              <li>
                <h3 className="text-lg font-semibold">Marketing:</h3>
                SproutsAI Inc. may use cookies to help deliver marketing campaigns and
                track their performance (e.g., a user visited sproutsai and then made
                a purchase). Similarly, SproutsAI Inc.’s partners may use cookies to
                provide us with information about your interactions with their
                services, but use of those third-party cookies would be subject to the
                service provider’s policies.
              </li>
              <li>
                <h3 className="text-lg font-semibold">Performance, Analytics, and Research:</h3>
                Cookies help SproutsAI Inc. learn how well the Sites and Services
                perform. SproutsAI Inc. also uses cookies to understand, improve, and
                research products, features, and services, including to create logs
                and record when you access our Sites and Services from different
                devices, such as your work computer or your mobile device.
              </li>
            </ul>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">What third-party cookies does SproutsAI Inc. use?</h2>
            <p>
              You can find a list of the third-party cookies that SproutsAI Inc. uses
              on our sites along with other relevant information by clicking on the
              widget in the bottom left corner of the website.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">How are cookies used for advertising purposes?</h2>
            <p>
              Cookies and other ad technology such as beacons, pixels, and tags help
              SproutsAI Inc. market more effectively to users that may be interested
              in the Services. They also help with aggregated auditing, research, and
              reporting.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">What can you do if you don't want cookies to be set or want them to be removed?</h2>
            <p>
              You have the option to disable and delete cookies that may not be
              necessary for the basic functionality of our website. Please note,
              blocking categories may impact your experience on our website. You may
              access the Cookie Manager at any time by clicking on the widget in the
              bottom left corner of the website.
            </p>
          </div>

          <div className="mb-6 border-l-4 border-blue-400 pl-4">
            <h2 className="text-xl font-semibold text-[#66708]">Does SproutsAI Inc. respond to Do Not Track Signals?</h2>
            <p>
              The Sites and Services do not collect personal information about your
              online activities over time and across third-party websites or online
              services. Therefore, “do not track” signals transmitted from web
              browsers do not apply to the Sites or Services, and SproutsAI Inc. does
              not alter any data collection and use practices upon receipt of such a
              signal.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
