import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

const Subscribe = ({ isModalOpen, setIsModalOpen }) => {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phone:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      firstname: formData.firstName,
      lastname: formData.lastName,
      name: formData.firstName + " " + formData.lastName,
      email: formData.email,
      message: formData.message,
      phone: formData.phone,
      forReq: "demo",
      subject: "Contact Request - SproutsAI",
      to: "sales@sproutsai.com",
    });
  
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        window.alert("We will contact you soon");
        // Optionally, you can reset the form after a successful submission
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          phone: "",
        });
      })
      .catch(error => console.error('Error:', error));
  };
  


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '0px'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color with 50% opacity
      zIndex: 1000
      // This makes the overlay background transparent
    },
  };

  useEffect(() => {
    if (isModalOpen) {
      // When the modal is open, disable scrolling on the body element
      document.body.style.overflow = 'hidden';
    } else {
      // When the modal is closed, enable scrolling on the body element
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="Subscribe Modal"
      style={customStyles}
    >
      <div className="items-center align-bottom    rounded-lg    lg:col-span-3 lg:p-12">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="sr-only" htmlFor="name">
              Name
            </label>
            <input
              className="w-full rounded-lg border border-gray-200 p-3 text-sm"
              placeholder="Name"
              value={formData.firstName}
              onChange={handleChange}
              type="text"
              id="name"
            />
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label className="sr-only" htmlFor="email">
                Email
              </label>
              <input
                className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                placeholder="Email address"
                type="email"
                value={formData.email}
                onChange={handleChange}
                id="email"
              />
            </div>

            <div>
              <label className="sr-only" htmlFor="phone">
                Phone
              </label>
              <input
                className="w-full rounded-lg border border-gray-200 p-3 text-sm"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleChange}
                type="tel"
                id="phone"
              />
            </div>
          </div>

          <div>
            <label className="sr-only" htmlFor="message">
              Message
            </label>

            <textarea
              className="w-full rounded-lg resize-none border border-gray-200 p-3 text-sm"
              placeholder="Message"
              rows="8"
              value={formData.message}
              onChange={handleChange}
              id="message"
            ></textarea>
          </div>

          <div className="mt-4 flex justify-between">
            <button
              type="submit"
              className="bg-[#1369E9] flex items-center justify-center text-[#FFF] lg:text-xl text-[20px] font-semibold px-2 lg:px-[25px] py-[15px] rounded-[6px] shadow h-[40px]"
              onClick={() => setIsModalOpen(false)}
            >
              Send Enquiry
            </button>
            <button
              type="submit"
              className="absolute top-[10px] right-[10px]"
              onClick={() => setIsModalOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7.00195 7L17.002 17" stroke="#252E4B" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7 17L17 7" stroke="#252E4B" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Subscribe;
