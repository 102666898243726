import React, { useState } from 'react';
import Subscribe from './Subscribe';
import HomepageLogo from '../components/assets/HomepageLogo.png'
import { useInView } from "react-intersection-observer";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router';

export default function Home() {

  const nav = useNavigate()
  const slides = [
    'http://dogsdiscovered.com/wp-content/uploads/2011/03/growling-dog.jpg',
    'https://pbs.twimg.com/profile_images/498611745100156928/1x9OADbM_400x400.jpeg',
    'https://pbs.twimg.com/profile_images/473535319090819074/6j-F-_N-_400x400.jpeg',
    'https://cdn2.bigcommerce.com/server3800/b0iqeya/product_images/uploaded_images/wet-dog.png?t=1409100694',
    'https://4.bp.blogspot.com/_4vn24YA-Rc4/Sm6yCChb-PI/AAAAAAAAA8Q/gS5WgIYb754/s400/dogs+color+vision.PNG',
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  const nextSlide = () => {
    setActiveSlide((activeSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };




  function fetchPublicJobs() {
    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
    };
    const body = {
      "firstname": "Pankaj",
      "lastname": "Chouhan",
      "name": "Pankaj Chouhan",
      "phone": "8770631461",
      "message": "My name is pankaj",
      "forReq": "demo",
      "subject": "Demo Request - SproutsAI",
      "email": "pankaj@sproutsai.com",
      "to": "pankaj@sproutsai.com"
    }

    fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
      .then(response => response.json())
      .then(result => console.log())
      .catch(error => console.log('error', error));
  }

  const [ref1, inView1] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref3, inView3] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref4, inView4] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref5, inView5] = useInView({
    triggerOnce: false, // Only trigger once
  });
  const [ref6, inView6] = useInView({
    triggerOnce: false, // Only trigger once
  });




  const CompanyImages = [

    "images/Vectors-Wrapper_3.svg",
    "images/Vectors-Wrapper_4.svg",
    "images/Vectors-Wrapper_5.svg",
    // "images/Vectors-Wrapper_6.svg",
    "images/Vectors-Wrapper_7.svg",
    "images/Vectors-Wrapper_8.svg",
    "images/Vectors-Wrapper_9.svg",
    "images/Vectors-Wrapper_10.svg",
    "images/AlphaNumero.svg",
    "images/GlobalFair.svg",
    "images/NiralNetwork.svg",
    "images/Yatra.svg"

  ]

  const hirinfText = [
    { heading: "Requirement Discussion", text: "Thorough discussion on Job roles and defining ideal candidates", img: "images/1.png" },
    { heading: "Market Research and analysis", text: "Our Data-driven hiring provide real time data on market trend, salary, competitor analysis", img: "images/2.png" },
    { heading: "Sourcing/Shortlisting", text: "Faster sourcing capabilities throughour AI tools.", img: "images/3.png" },
    { heading: "SproutsAI Interview", text: "We gauge the candidate on 3 parameters: Productivity, Value Addition, Culture.", img: "images/4.png" },
    { heading: "Client Interviews", text: "We strive to facilitate meaningful interactions that lead to informed hiring decisions", img: "images/5.png" },
    { heading: "Onboarding and Documentation", text: "We take care of follow ups and provide support in background checking", img: "images/6.png" },
  ]

  const [activeTab, setActiveTab] = useState(1)
  const [qsnTab, setqsnTab] = useState(1)

  const faqsList = [
    {
      q: "Executive Search",
      a: <ul role="list" className="list-none flex flex-wrap">
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/user-circle.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Identify and recruit top-level executives for
            leadership positions
          </div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/search-normal.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Extensive market research and candidate mapping
          </div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/donate-coin.svg"
            loading="lazy"
            alt=""
          />
          <div>End to End recruitment</div>
        </li>
      </ul>,
    },
    {
      q: "Engineering Hiring",
      a: <ul role="list" className="list-none flex flex-wrap">
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/video.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Source and screen highly skilled technical
            professionals
          </div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/mobile-programming.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Assess technical expertise and problem-solving
            abilities
          </div>
        </li>
        <li className="flex items-center w-[50%] hide">
          <img className="mr-[12px]"
            src="images/donate-coin.svg"
            loading="lazy"
            alt=""
          />
          <div>End to End recruitment</div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/brifecase-timer.svg"
            loading="lazy"
            alt=""
          />
          <div>Dedicated recruiter and account manager</div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/Logo---symbol-2.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Subscription to our inhouse AI recruitment platform
          </div>
        </li>
      </ul>
      ,
    },
    {
      q: "Recruitment Process Outsourcing",
      a: <ul role="list" className="list-none flex flex-wrap">
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/programming-arrow.svg"
            loading="lazy"
            alt=""
          />
          <div className="text-block-6">
            We offer end-to-end RPO solutions tailored to your
            organization's specific needs
          </div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/pen-tool-2-1.svg"
            loading="lazy"
            alt=""
          />
          <div className="text-block-8">
            Our comprehensive recruitment services eliminate the
            need for additional hiring tools
          </div>
        </li>
        <li className="flex items-center w-[50%] hide">
          <img className="mr-[12px]"
            src="images/donate-coin.svg"
            loading="lazy"
            alt=""
          />
          <div>End to End recruitment</div>
        </li>
        <li className="flex items-center w-[50%] hide">
          <img className="mr-[12px]" src="images/setting.svg" loading="lazy" alt="" />
          <div className="text-block-7">
            Our RPO services are designed to accommodate your
            changing staffing requirements
          </div>
        </li>
        <li className="flex items-center w-[50%] hide">
          <img className="mr-[12px]"
            src="images/Logo---symbol-2.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Subscription to our inhouse AI recruitment platform
          </div>
        </li>
      </ul>
      ,
    },
    {
      q: "Other search",
      a: <ul role="list" className="list-none flex flex-wrap">
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/chart-arrow-up-01.svg"
            loading="lazy"
            alt=""
          />
          <div className="text-block-6">
            Quickly scale your workforce with temporary or
            contract employees
          </div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]"
            src="images/pen-tool-2-1.svg"
            loading="lazy"
            alt=""
          />
          <div className="text-block-8">
            Our comprehensive recruitment services eliminate the
            need for additional hiring tools
          </div>
        </li>
        <li className="flex items-center w-[50%] hide">
          <img className="mr-[12px]"
            src="images/donate-coin.svg"
            loading="lazy"
            alt=""
          />
          <div>End to End recruitment</div>
        </li>
        <li className="flex items-center w-[50%]">
          <img className="mr-[12px]" src="images/setting.svg" loading="lazy" alt="" />
          <div className="text-block-7">
            We actively cater to hiring needs in the
            &nbsp;finance/banking/investment/healthcare domain.
          </div>
        </li>
        <li className="flex items-center w-[50%] hide">
          <img className="mr-[12px]"
            src="images/Logo---symbol-2.svg"
            loading="lazy"
            alt=""
          />
          <div>
            Subscription to our inhouse AI recruitment platform
          </div>
        </li>
      </ul>
      ,
    },
  ];



  const qnalist = [
    {
      q: "Why is SproutsAI priced per hiring and not an annual subscription?",
      a: "Sprouts AI uses consumption-based pricing. You only pay for what you use. This way, you’re not paying for excess capacity or unused functionality. Our consumption-based pricing is modeled after tech leaders like Snowflake and AWS."
    },
    {
      q: "Can we purchase more candidate bundles during the current license period?",
      a: "Yes, you can always purchase additional hiring bundle credits during the year. However, if you anticipate needing significantly more conversation credits, it’s best to purchase those up front to secure the deepest volume discount."
    },
    {
      q: "Will we receive monthly invoices?",
      a: "Yes. Even though you’re purchasing it up-front, you’ll receive a monthly invoice with the number of hiring credits used, the cost associated with those used credits, and the number of credits remaining in your account."
    },
    {
      q: "What ATS provider the solution integrate with?",
      a: "We currently integrate with Greenhouse, Lever, Ashby, and Workable. If you have requests for other ATS providers, please let us know at talent@sproutsai.com"
    }
  ]
  const [activeSearch, setActiveSearch] = useState(0)
  return (
    <div className="">
      <div className="relative">
        <img
          src="https://sproutsweb-assets.s3.amazonaws.com/images/hero1.jpeg"
          className="absolute inset-0 object-cover w-full h-full"
          alt=""
        />
        <div className="relative bg-gray-900 bg-opacity-75">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between xl:flex-row">

              <div className="w-full max-w-xl xl:px-8 xl:w-5/12">
                <div className="bg-white rounded shadow-2xl p-7  sm:p-10">
                  <form className='flex flex-col justify-center '>

                    <input
                      placeholder="Your name"
                      required
                      type="text"
                      className="flex-grow mt-7 h-12 px-4 mb-2 transition duration-200  bg-[white]  text-base font-normal pl-4 rounded-lg border-[#e1e1ee] border text-[#5f6989] shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="firstName"
                      name="firstName"
                    />


                    <input
                      placeholder=" Your email"
                      required
                      type="text"
                      className="flex-grow mt-7 h-12 px-4 mb-2 transition duration-200  bg-[white]  text-base font-normal pl-4 rounded-lg border-[#e1e1ee] border text-[#5f6989] shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="lastName"
                      name="lastName"
                    />



                    <input
                      placeholder=" Your phone number"
                      required
                      type="text"
                      className="flex-grow mt-7 h-12 px-4 mb-2 transition duration-200  bg-[white]  text-base font-normal pl-4 rounded-lg border-[#e1e1ee] border text-[#5f6989] shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="email"
                      name="email"
                    />
                    <input
                      placeholder="Are you a funded startup"
                      required
                      type="text"
                      className="flex-grow mt-7 h-12 px-4 mb-2 transition duration-200  bg-[white]  text-base font-normal pl-4 rounded-lg border-[#e1e1ee] border text-[#5f6989] shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      id="email"
                      name="email"
                    />

                    <div className="mt-4 mb-2 sm:mb-4">
                      <button
                        type="submit"
                        className="inline-flex mt-7 text-[20px] font-semibold bg-[#1369E9] border-none items-center justify-center w-full h-12 px-6  tracking-wide text-white transition duration-200 rounded  bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                      >
                        Get a Dedicated Recruiter
                      </button>
                    </div>

                  </form>
                </div>
              </div>
              <div className="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">
                <h2 className="max-w-lg mb-6  text-[25px] font-semibold tracking-tight text-white  sm:leading-[30px]">
                  We drive organizational success through<br />
                  extraordinary
                  <strong className=" font-semibold text-[#1369E9]">
                    {" Talent Acquisition"}
                  </strong> <br className="hidden md:block" />
                </h2>
                <p className="max-w-xl mb-4 text-[18px] text-gray-400 md:text-lg">
                  We provide our hiring solutions across North America,
                  <br />
                  South America, Latin America and India.
                </p>
                <a
                  href="/"
                  aria-label=""
                  className="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700"
                >
                  {/* Learn more */}
                  <svg
                    className="inline-block w-3 ml-2"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref5} className={`flex justify-center your-element  ${inView5 ? "fade" : ""}`}>
        <div className="logo-title w-full grid grid-cols max-w-screen-xl  gap-x-6 py-10  justify-start items-center  px-0 ">
          <h1 className=" text-center mt-0 mb-14 text-2xl md:text-3xl lg:text-4xl font-bold text-gray-700 leading-10">We are trusted by leading companies</h1>
          <div className=''>
            <div className=" flex justify-center ">
              <Carousel autoFocus={true} transitionTime={1000} autoPlay={true} showThumbs={false} showStatus={false} interval={1500} emulateTouch={true} infiniteLoop={true} showIndicators={false} stopOnHover={false} centerMode={true} centerSlidePercentage={25} >
                {CompanyImages?.map((item, index) => (
                  <div key={index}>
                    <img src={item} alt={`image ${index}`} className='w-[100px] h-[15px] md:h-[30px] lg:h-[30px]  object-contain mr-[20px]' />
                  </div>
                ))}

              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className={`flex flex-col items-center  bg-gray-50 `}>
        <div className="flex flex-col       max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
          <section className="overflow-hidden  sm:grid sm:grid-cols-2">
            <div className="  lg:py-24">
              <div
                className="mx-auto max-w-xl  ltr:sm:text-left rtl:sm:text-right"
              >
                <span className="text-[#16182F] font-normal text-[14px] leading-[1px] text-center">Looking for <span className='text-[#1369E9]'>an expert!</span> </span>
                <h2 className="text-2xl font-bold text-gray-900 md:text-3xl mt-[10px]">
                  Find top talents from sproutium.
                </h2>

                <p className="hidden text-gray-500 md:mt-4 md:block">
                Say goodbye to talent shortages and hello to your next superstar! Sproutium is your one-stop shop for finding top talent across all industries. With a diverse pool of pre-screened candidates and a commitment to finding the perfect match, we take the hassle out of recruitment. Ready to unlock your team's full potential? Let Sproutium help you find the experts you need to thrive!
                </p>

                <div className="mt-4 md:mt-8">
                  <a
                    href="#"
                    className="inline-block rounded  px-12 py-3 text-sm font-medium text-[#1369E9] border-[2px] border-[#1369E9] transition  focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    Find talents
                  </a>
                </div>
              </div>
            </div>

            <img
              alt="Student"
              src="https://images.unsplash.com/photo-1464582883107-8adf2dca8a9f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
              className="h-56 w-full object-cover sm:h-full"
            />
          </section>
        </div>
      </div>




      <div className={`flex flex-col items-center   `}>
        <div className="flex flex-col       max-w-screen-xl   px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
          <div className=''>
            <span className="text-[#16182F] font-normal text-[14px] leading-[1px]">How <span className='text-[#1369E9]'>we do!</span> </span>
            <h1 className="text-[#16182F] font-semibold text-[28px] leading-[40px]"> Recruitment process</h1>

          </div>
          <div className='flex flex-col justify-center items-center lg:flex-row mt-[30px]'>

            <div className="w-full  md:w-full lg:w-3/6 sm:w-full pr-[20px]">
              <div className=" mx-auto h-auto ">

                <div className="">
                  {hirinfText?.map((item, index) => (
                    <a key={index}
                      className={`${activeTab === index + 1 ? "bg-[#9bb2d4]" : "bg-none"} mt-[12px] flex items-center rounded-[12px] py-[18px] px-[22px]`}
                      onClick={e => setActiveTab(index + 1)}
                    >

                      <div className=" flex ">
                        <div className="step-count">
                          <div className={` w-[32px] h-[32px] flex items-center justify-center ${activeTab === index + 1 ? "bg-[#FFF]" : "bg-[#9bb2d4]"}  ${activeTab === index + 1 ? "text-[#16182F]" : "text-[#FFF]"} rounded-[50%] font-medium text-[16px] leading-[17px]`}>0{index + 1}</div>
                        </div>
                        <div className=" ml-[14px]">
                          <div className={`${activeTab === index + 1 ? "text-[#FFF]" : "text-[#16182F]"}  font-medium text-[20px] leading-[17px]`}>{item?.heading}</div>
                          <div className={`mt-[10px] ${activeTab === index + 1 ? "text-[#FFF]" : "text-[#16182F]"}   text-[14px] font-normal leading-[24px]`}>
                            {item.text}
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="hidden lg:block md:block md:w-full lg:w-3/6 sm:w-full mt-[50px] lg:mt-0 ">
              <img
                loading="lazy"
                src={hirinfText[activeTab - 1]?.img}
                alt="Leafs"
                className="  h-[500px] object-contain rounded-[12px]"
              />
            </div>
          </div>

        </div>
      </div>


      <section className="py-[100px] bg-[#f7f7f7]">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="relative font-sans ">
            <div className="  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl   ">
              <div className="  text-center ">

                <span className="text-[#16182F] font-normal text-[14px] leading-[1px] text-center">What <span className='text-[#1369E9]'>we are!</span> </span>
                <h1 className="text-[#16182F] font-semibold text-[28px] leading-[40px] text-center"> Our Core Values</h1>
                <div className="grid grid-cols-3 gap-6 mt-[30px]">

                  <li className="list-item-4 list-none bg-[#FFF] rounded-[8px] p-[25px]">
                    <span className='flex items-center border-b border-b-[rgba(0,0,0,.06)] pb-[15px] text-[19px] font-semibold text-[#111922] gap-[10px] not-italic'>
                      <img
                        src="images/Vectors-Wrapper_12.svg"
                        loading="lazy"
                        width={52}
                        height={52}
                        alt=""
                        className="vectors-wrapper-17 "

                      />
                      Integrity
                    </span>
                    <div className="mt-[15px]">
                      <div className="text-22">
                        We uphold the highest standards of ethics, honesty, and
                        transparency in all our interactions.
                      </div>
                    </div>
                  </li>
                  <li className="list-item-4 list-none bg-[#FFF] rounded-[8px] p-[25px]">
                    <span className='flex items-center border-b border-b-[rgba(0,0,0,.06)] pb-[15px] text-[19px] font-semibold text-[#111922] gap-[10px] not-italic'>
                      <img
                        src="images/friendly-1.svg"
                        loading="lazy"
                        width={40}
                        height={40}
                        alt=""
                        className="vectors-wrapper-17 "
                      />
                      Collaboration
                    </span>
                    <div className="mt-[15px]">

                      <div className="text-22">
                        We believe that collective efforts and diverse
                        perspectives lead to better outcomes
                      </div>
                    </div>
                  </li>
                  <li className="list-item-4 list-none bg-[#FFF] rounded-[8px] p-[25px]">
                    <span className='flex items-center border-b border-b-[rgba(0,0,0,.06)] pb-[15px] text-[19px] font-semibold text-[#111922] gap-[10px] not-italic'>
                      <img
                        src="images/arranged-1.svg"
                        loading="lazy"
                        width={40}
                        height={40}
                        alt=""
                        className="vectors-wrapper-17 "
                      />
                      Client-Centric Approach
                    </span>
                    <div className="mt-[15px]">
                      <div className="text-22">
                        Client-centric approach, tailoring solutions to meet
                        specific business objectives.
                      </div>
                    </div>
                  </li>
                  <li className="list-item-4 list-none bg-[#FFF] rounded-[8px] p-[25px]">
                    <span className='flex items-center border-b border-b-[rgba(0,0,0,.06)] pb-[15px] text-[19px] font-semibold text-[#111922] gap-[10px] not-italic'>
                      <img
                        src="images/care-1.svg"
                        loading="lazy"
                        width={40}
                        height={40}
                        alt=""
                        className="vectors-wrapper-17 "
                      />
                      Respect
                    </span>
                    <div className="mt-[15px]">
                      <div className="text-22">
                        We treat every individual with respect, dignity, and
                        fairness. We value diversity and inclusivity
                      </div>
                    </div>
                  </li>
                  <li className="list-item-4 list-none bg-[#FFF] rounded-[8px] p-[25px]">
                    <span className='flex items-center border-b border-b-[rgba(0,0,0,.06)] pb-[15px] text-[19px] font-semibold text-[#111922] gap-[10px] not-italic'>
                      <img
                        src="images/charisma-1.svg"
                        loading="lazy"
                        width={40}
                        height={40}
                        alt=""
                        className="vectors-wrapper-17 "
                      />
                      Accountability
                    </span>
                    <div className="mt-[15px]">
                      <div className="text-22">
                        We take ownership of our actions, decisions, and
                        commitments.
                      </div>
                    </div>
                  </li>
                  <li className="list-item-4 list-none bg-[#FFF] rounded-[8px] p-[25px]">
                    <span className='flex items-center border-b border-b-[rgba(0,0,0,.06)] pb-[15px] text-[19px] font-semibold text-[#111922] gap-[10px] not-italic'>
                      <img
                        src="images/literature-1.svg"
                        loading="lazy"
                        width={40}
                        height={40}
                        alt=""
                        className="vectors-wrapper-17 "
                      />
                      Continuous Learning
                    </span>
                    <div className="mt-[15px]">
                      <div className="text-22">
                        Encouraging team growth through continuous learning and
                        industry awareness.
                      </div>
                    </div>
                  </li>
                  <div className="column-6 padding-0 w-col w-col-6">
                    <ul role="list" className="list-3 w-list-unstyled">

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <div className="max-w-screen-xl mx-auto md:px-8 py-[100px]">
        <div className="relative font-sans ">
          <div className='mb-[30px]'>
            <h1 className='text-center text-[#16182F] font-semibold text-[28px] leading-[40px] '>Questions & Answers</h1>
          </div>
          {qnalist.map((item, idx) => (
            <div className='border-b border-b-[rgba(0,0,0,.06)] py-[20px] cursor-pointer' onClick={e => setqsnTab(idx)}>
              <div className='flex justify-between'>
                <h1 className='text-[18px] font-medium not-italic'>{item.q}</h1>
                {qsnTab === idx ? (
                  <span className='rounded-[50%]  text-[30px] font-bold'>-</span>
                ) : (
                  <span className='text-[30px] font-bold'>+</span>
                )}
              </div>
              {qsnTab === idx &&
                <div className='w-[70%] mt-[10px]'>
                  {item?.a}

                </div>
              }
            </div>
          ))}
          <div className='text-center mt-[30px]'>
            <span className='bg-[#f1f6f3] rounded-[30px] py-[3px] px-[25px] text-[16px]'>Don’t find the answer? We can help. <a href="" className='text-[#1369E9]'>Click here</a> </span>
          </div>
        </div>
      </div>


      <div className="max-w-screen-xl mx-auto md:px-8 py-[100px]">
        <div className="relative font-sans ">
        <div className="flex flex-col   bg-gray-50 relative overflow-hidden   max-w-screen-xl  rounded-[16px]  px-4 sm:px-6 lg:px-8 py-[40px] lg:py-[70px]">
          <span className='absolute bg-[rgba(36,159,70,.1)] rounded-[50%] w-[100px] h-[100px] top-[-40px] left-[-40px]'></span>
          <span className='absolute bg-[rgba(36,159,70,.1)] rounded-[50%] w-[100px] h-[100px] top-[-40px] right-[-40px]'></span>
          <span className='absolute bg-[rgba(36,159,70,.1)] rounded-[50%] w-[100px] h-[100px] bottom-[-40px] left-[-40px]'></span>
          <span className='absolute bg-[rgba(36,159,70,.1)] rounded-[50%] w-[100px] h-[100px] bottom-[-40px] right-[-40px]'></span>
          <section className="overflow-hidden   sm:grid sm:grid-cols-2">
           

            <img
              alt="Student"
              src="https://jobi-nextjs.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fimg_41.c8952458.png&w=640&q=75"
              className="h-56 w-full object-cover sm:h-full text-left"
            />
             <div className="  lg:py-24">
              <div
                className="mx-auto max-w-xl  ltr:sm:text-left rtl:sm:text-right"
              >
                <h2 className="text-4xl font-extrabold text-gray-900 md:text-4xl mt-[10px]">
                Get your Matched Jobs <br /> in a few minutes.
                </h2>

                <p className="hidden text-gray-500 md:mt-4 md:block">
                Find your dream job & earn from world leading brands.<br /> Upload your CV now.
                </p>

                <div className="mt-4 md:mt-8">
                  <button
                    href="#"
                    className=" rounded flex items-center justify-center px-12 py-3 text-sm font-medium bg-[#1369E9] border-[2px] border-[#1369E9] text-[#FFF] transition  focus:outline-none focus:ring focus:ring-yellow-400"
                  >
                    <img src="https://jobi-nextjs.vercel.app/_next/static/media/icon_46.fa2067d5.svg" alt="" />
                    <span className='ml-[10px] font-medium'>Upload your CV</span>
                    
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
    </div>
  )
}
