import React from 'react'

export default function SalesAndMarketing() {
  return (
    <>
    <div className='bg-[#F3F5F9] py-20' >
    <section class="">
    <div class="container flex flex-col items-center px-4  mx-auto text-center">
        <h2 class="text-gray-800 font-bold text-4xl md:text-5xl ">
        Sales and <span class="text-indigo-600">Marketing</span>
        </h2>

    </div>
</section>

<section>
  <div
    class="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8"
  >
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div
        class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
      >
        <img
          alt="Party"
          src="images/about3.png"
          class="absolute inset-0 h-full w-full object-cover"
        />
      </div>

      <div class="lg:py-24">
        <h2 class="text-3xl font-bold sm:text-4xl">Automate and personalize Sales and Marketing</h2>

        <p class="mt-4 text-gray-600">
        Sproutsai's AI-powered autonomous agents can help you with all your sales and marketing tasks, from generating leads to qualifying leads to closing deals. Our agents can also create personalized content for your customers and nurture your leads.

        </p>

        <div className='mt-5 mb-5'>
                    <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl ">Key Features</h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
                        <span class="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
                    </div>
                </div>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
        <a
          className="block rounded-xl border bg-[#FFF] border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
          href="/accountant"
        >
          <h2 className="mt-2 font-bold">Lead List Building</h2>
        </a>

        <a
          className="block rounded-xl border bg-[#FFF] border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
          href="/accountant"
        >
          <h2 className="mt-2 font-bold">Personalized Outreach</h2>
        </a>
        <a
          className="block rounded-xl bg-[#FFF] border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
          href="/accountant"
        >
          <h2 className="mt-2 font-bold">Lead Qualification</h2>
        </a>
        <a
          className="block rounded-xl bg-[#FFF] border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
          href="/accountant"
        >
          <h2 className="mt-2 font-bold">Meeting Scheduling</h2>
        </a>
        <a
          className="block rounded-xl bg-[#FFF] border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
          href="/accountant"
        >
          <h2 className="mt-2 font-bold">Proposal and Document Creation</h2>
        </a>

      </div>

      </div>
    </div>
  </div>
</section>

</div>



    </>
  )
}
