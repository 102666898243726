import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';

function SuccessModal({ isModalOpen, setIsModalOpen }) {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        phone:""
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
      
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
      
        const raw = JSON.stringify({
          firstname: formData.firstName,
          lastname: formData.lastName,
          name: formData.firstName + " " + formData.lastName,
          email: formData.email,
          message: formData.message,
          phone: formData.phone,
          forReq: "demo",
          subject: "Contact Request - SproutsAI",
          to: "sales@sproutsai.com",
        });
      
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
      
        fetch("https://api.sproutsai.com/controls/send-demo-mail", requestOptions)
          .then(response => response.text())
          .then(result => {
            console.log(result)
            window.alert("We will contact you soon");
            // Optionally, you can reset the form after a successful submission
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
              phone: "",
            });
          })
          .catch(error => console.error('Error:', error));
      };
      
    
    
      const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '0px'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Background color with 50% opacity
          zIndex: 1000
          // This makes the overlay background transparent
        },
      };
    
      useEffect(() => {
        if (isModalOpen) {
          // When the modal is open, disable scrolling on the body element
          document.body.style.overflow = 'hidden';
        } else {
          // When the modal is closed, enable scrolling on the body element
          document.body.style.overflow = 'auto';
        }
      }, [isModalOpen]);

    
  return (
    <Modal
    isOpen={isModalOpen}
    onRequestClose={() => setIsModalOpen(false)}
    contentLabel="Subscribe Modal"
    style={customStyles}
  >
    <div className="items-center align-bottom  flex justify-center flex-col  rounded-lg    lg:col-span-3 lg:p-12">
     <h1 className='text-[30px] font-semibold'>Thank you for your interest in SproutsAI. </h1>
     <span className='mt-[10px]'>We'll be in touch to schedule a personalised demo for you soon</span>
     <button
              type="button"
              className="absolute top-[10px] right-[10px]"
              onClick={() => setIsModalOpen(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7.00195 7L17.002 17" stroke="#252E4B" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7 17L17 7" stroke="#252E4B" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
    </div>
  </Modal>
  )
}

export default SuccessModal