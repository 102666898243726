import React, { useRef, useState } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";

export default function AutonomousAgentPlatform() {

  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(true)

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const fileInputRef = useRef(null);
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedFile(file);
  // };

  const openFileDialog = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };




  const [formData, setFormData] = useState({
    fullName: null,
    email: null,
    phone: null,
    salary: null,
    position: null,
    file: null,
  });

  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
    if(e.target.files[0]){
      setDisabled(false)
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setDisabled(true)
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post('http://localhost:3333/candidate/public-uploadresume', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(response)
      setResponse(JSON.stringify(response.data));
      openModal()
      setDisabled(false)
      setFormData({
        fullName: null,
        email: null,
        phone: null,
        salary: null,
        position: null,
        file: null,
      })
      setError('');
    } catch (error) {
      setDisabled(false)
      setResponse(null);
      closeModal()
      setError('An error occurred while uploading the resume.');
    }
  };
  return (
    <div className="bg-[#F3F5F9]">
       <div className="relative h-[30vh]">
        <img
          src="https://www.themetechmount.com/react/hireco/images/pagetitle-bg.jpg"
          className="absolute inset-0 object-cover w-full h-full"
          alt=""
        />
        <div className="relative bg-gray-900 h-full bg-opacity-70">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between ">
              <h1 className='text-[#FFF] text-[32px] not-italic font-semibold'>  Submit Resume</h1>

            </div>
          </div>
        </div>
      </div>
      <section className=" mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
      <>
      <div>
        <div className=" flex flex-col items-center">
          <span className="text-[#16182F] text-[36px] not-italic font-bold">
          
          </span>
          <span className="mt-[20px] text-[#5F6989] text-[16px] not-italic font-normal">
            Receive Job Alerts, Tailored to your profile through
          </span>
          <span className=" text-[#5F6989] text-[16px] not-italic font-normal">
            our AI matchmaking.
          </span>
          <div className='mt-[32px] border border-[#E1E1EE] py-[40px] px-[48px] w-full rounded-[22px] bg-[#fff] shadow-["0px 2px 6px 0px rgba(62, 72, 84, 0.10)"]'>
            <span className="text-[18px]  flex items-center text-[#16182F] mt-[28px] font-semibold not-italic">
              <svg
                className="mr-[12px]"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <g
                  id="63c5ac1b05568908a811c85e_personal-information-icon-workplace-x-webflow-template 1"
                  clip-path="url(#clip0_1_23754)"
                >
                  <path
                    id="Vector"
                    d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                    fill="#4BA9FF"
                  />
                  <path
                    id="Vector_2"
                    d="M16.0018 16.4411C18.3327 16.4411 20.2223 14.5515 20.2223 12.2205C20.2223 9.8896 18.3327 8 16.0018 8C13.6709 8 11.7812 9.8896 11.7812 12.2205C11.7812 14.5515 13.6709 16.4411 16.0018 16.4411Z"
                    fill="white"
                  />
                  <path
                    id="Vector_3"
                    d="M23.0787 23.9999C23.4672 23.9999 23.7854 23.6843 23.7504 23.2974C23.6796 22.5163 23.491 21.7491 23.1898 21.022C22.7987 20.0779 22.2256 19.2201 21.503 18.4975C20.7804 17.7749 19.9225 17.2017 18.9784 16.8106C18.0343 16.4196 17.0224 16.2183 16.0005 16.2183C14.9786 16.2183 13.9667 16.4196 13.0226 16.8106C12.0785 17.2017 11.2207 17.7749 10.4981 18.4975C9.77551 19.2201 9.20232 20.0779 8.81126 21.022C8.5101 21.7491 8.32149 22.5163 8.25069 23.2974C8.21562 23.6843 8.53385 23.9999 8.92234 23.9999H16.0005H23.0787Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_23754">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Personal Information
            </span>

            <div className="flex gap-[20px] mt-[20px]">
            <div className="w-[50%]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Full name
              </label>
              <input
                type="text"
                name="fullName" value={formData.fullName} onChange={handleInputChange}
                id=""
                className="w-full h-[48px] border border-[#eee] text-lg pl-4  rounded-[8px]"
              />
            </div>
            <div className="w-[50%]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Email
              </label>
              <input
                type="email"
                name="email" value={formData.email} onChange={handleInputChange}
                id=""
                className="w-full h-[48px] border border-[#eee] text-lg pl-4  rounded-[8px]"
              />
            </div>
            </div>


            <div className="mb-[20px] mt-[20px] w-[50%] pr-[10px]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Phone number
              </label>
              <input
                type="number"
                name="phone" value={formData.phone} onChange={handleInputChange}
                id=""
                className="w-full h-[48px] border border-[#eee] text-lg pl-4  rounded-[8px]"
              />
            </div>


            <span className="text-[18px] flex items-center text-[#16182F]  font-semibold not-italic mt-[53px] ">
              <svg
                className="mr-[12px]"
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <g
                  id="63c5ac1a69556d50998d9b04_portfolio-icon-workplace-x-webflow-template 1"
                  clip-path="url(#clip0_1_23765)"
                >
                  <path
                    id="Vector"
                    d="M16.0645 32.2666C24.901 32.2666 32.0645 25.1032 32.0645 16.2666C32.0645 7.43005 24.901 0.266602 16.0645 0.266602C7.2279 0.266602 0.0644531 7.43005 0.0644531 16.2666C0.0644531 25.1032 7.2279 32.2666 16.0645 32.2666Z"
                    fill="#6C38FF"
                  />
                  <path
                    id="Vector_2"
                    d="M19.8406 11.1894L19.8496 11.2412H19.9022H23.3488C23.6915 11.2412 24.0203 11.3774 24.2626 11.6198C24.505 11.8622 24.6412 12.1909 24.6412 12.5337V23.3729C24.6412 23.7157 24.505 24.0444 24.2626 24.2868C24.0203 24.5292 23.6915 24.6654 23.3488 24.6654H8.44475C8.10199 24.6654 7.77326 24.5292 7.53088 24.2868C7.28851 24.0444 7.15234 23.7157 7.15234 23.3729V12.5337C7.15234 12.1909 7.28851 11.8622 7.53088 11.6198C7.77326 11.3774 8.10199 11.2412 8.44475 11.2412H11.8913H11.9439L11.9529 11.1894C12.1134 10.2598 12.5972 9.41674 13.3188 8.80907C14.0403 8.2014 14.9534 7.86816 15.8968 7.86816C16.8401 7.86816 17.7532 8.2014 18.4748 8.80907C19.1964 9.41674 19.6801 10.2598 19.8406 11.1894ZM15.8969 9.08388C15.2825 9.08505 14.6855 9.28969 14.1996 9.66583C13.7138 10.042 13.3662 10.5684 13.2111 11.163L13.1907 11.2412H13.2716H18.5219H18.6028L18.5824 11.163C18.4273 10.5684 18.0797 10.042 17.5939 9.66583C17.1081 9.28969 16.5113 9.08505 15.8969 9.08388Z"
                    fill="white"
                    stroke="#6C38FF"
                    stroke-width="0.125"
                  />
                  <path
                    id="Vector_3"
                    d="M24.7366 15.5625H7.09375"
                    stroke="#6C38FF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_23765">
                    <rect width="33" height="33" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Designation information
            </span>

            <div className="flex gap-[20px] mt-[20px]">
            <div className="w-[50%]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Salary requirement
              </label>
              <input
                type="text"
                name="salary" value={formData.salary} onChange={handleInputChange}
                id=""
                className="w-full h-[48px] border border-[#eee] text-lg pl-4  rounded-[8px]"
              />
            </div>
            <div className="w-[50%]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Notice period
              </label>
              <input
                type="text"
                name=""
                id=""
                className="w-full h-[48px] border border-[#eee] text-lg pl-4  rounded-[8px]"
              />
            </div>
            </div>


            <div className="flex gap-[20px] mt-[20px]">


            <div className="w-[50%]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Ideal role/position
              </label>
              <input
                type="text"
                name="position" value={formData.position} onChange={handleInputChange}
                id=""
                className="w-full h-[48px] border border-[#eee] text-lg pl-4  rounded-[8px]"
              />
            </div>

            <div className="w-[50%]">
              <label
                htmlFor=""
                className="text-[14px] text-[#252E4B] mt-[28px] font-normal not-italic"
              >
                Submit resume
              </label>
              <div className="">
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <label
                  htmlFor="fileInput"
                  className="text-[#252E4B] text-base font-inter font-normal leading-6 rounded-lg border border-gray-600 flex justify-center bg-white py-[10px] px-[12px] w-[173px] cursor-pointer hover:bg-gray-100"
                  onClick={openFileDialog}
                >
                  <svg
                    className="mr-[12px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    {/* Your SVG code here */}
                  </svg>
                  Upload resume
                </label>
                {selectedFile && (
                  <div className="text-center">
                    <p className="mb-2">Selected File: {selectedFile.name}</p>
                    {/* <button
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
            onClick={()=>{}}
          >
            Upload
          </button> */}
                  </div>
                )}
              </div>

            </div>
            </div>

            <div className="mt-[48px] flex items-center">
              <button disabled={disabled} className={`py-[12px] px-[22px] rounded-[8px] ${disabled ? 'bg-gray-300' : 'bg-[#1369E9]'
                }`} onClick={handleSubmit}>
                <span className="text-[#fff] tracking-wide">Submit</span>{" "}
              </button>
              <button className="ml-[42px] py-[12px] px-[22px] tracking-wide rounded-[8px]">Clear form</button>
            </div>
          </div>
        </div>
      </div>


      {
        isOpen &&
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="modal-dialog mx-auto relative bg-white p-4 rounded-lg w-1/2">
            <div className="modal-content">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Upload Successful</h2>
                <button
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
              {/* Add your success message content here */}
              <p>Your resume was successfully uploaded.</p>
            </div>
          </div>
        </div>
      }
    </>
      </section>
    
    </div>
  );
}
