import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Header from "./screen/HeaderNew"
import Header2 from "./screen/Header"
import Footer from "./screen/Footer";
import { Pricing } from "./screen/Pricing";
import Login from "./screen/Login";
import Company from "./screen/Company";
import ContactUs from "./screen/ContactUs";
import SignUp from "./screen/SignUp";
import Blog from "./screen/Blog";
import Home from "./screen/HomeNew";
import Product from "./screen/Product";
import Subscribe from "./screen/Subscribe";
import HRSolution from "./screen/HRSolution";
import FAQ from "./screen/FAQs";
import SalesAndMarketing from "./screen/SalesAndMarketing";
import CustomerServiceAutomationPage from "./screen/CustomerServiceAutomationPage";
import { DevelopersRelation } from "./screen/DevelopersRelation";

import NewCarousel from "./screen/Crousal";
import AutonomousAgentPlatform from "./screen/AutonomousAgentPlatform";
import NoCodeBuilder from "./screen/NoCodeBuilder";
import Integrations from "./screen/Integrations";
import IndustrySolution from "./screen/IndustrySolution";
import Webinar from "./screen/Webinar";
import CaseStudy from "./screen/CaseStudy";
import Partnership from "./screen/Partnership";
import Career from "./screen/Career";
import Cookies from "./components/policy/Cookies";
import Privacy from "./components/policy/Privacy";
import TermsConditions from "./components/policy/TermsConditions";
function App() {
  return (
<Router>
  {/* <Header/> */}
  <Header2/>
  <Routes>
    <Route path="/" exact element = {<Home/>}/>
    {/* <Route path="/" exact element = {<NewCarousel/>}/> */}
    <Route path="/developerrelations" exact element = {<DevelopersRelation/>}/>
    <Route path="/hrsolution" exact element = {<HRSolution/>}/>
    <Route path="/submit-resume" exact element = {<AutonomousAgentPlatform/>}/>
    <Route path="/pricing" exact element = {<Pricing/>}/>
    <Route path="/product" exact element = {<Product/>}/>
    <Route path="/company" exact element = {<Company/>}/>
    <Route path="/login" exact element = {<Login/>}/>
    <Route path="/contact" exact element = {<ContactUs/>}/>
    <Route path="/signup" exact element = {<SignUp/>}/>
    <Route path="/blog" exact element = {<Blog/>}/>
    <Route path="/subscribe" exact element = {<Subscribe/>}/>
    <Route path="/faqs" exact element = {<FAQ/>}/>
    <Route path="/salesandmarketing" exact element = {<SalesAndMarketing/>}/>
    <Route path="/customerservice" exact element = {<CustomerServiceAutomationPage/>}/>
    <Route path="/excutiveSearch" exact element = {<NoCodeBuilder/>}/>
    <Route path="/integrations" exact element = {<Integrations/>}/>
    <Route path="/industries" exact element = {<IndustrySolution/>}/>
    <Route path="/webinars" exact element = {<Webinar/>}/>
    <Route path="/casestudy" exact element = {<CaseStudy/>}/>
    <Route path="/partnership" exact element = {<Partnership/>}/>
    <Route path="/career" exact element = {<Career/>}/>
    <Route path="/cookies" exact element = {<Cookies/>}/>
    <Route path="/privacy" exact element = {<Privacy/>}/>
    <Route path="/terms" exact element = {<TermsConditions/>}/>

    
  </Routes>  
  <Footer/>
</Router>
  );
}

export default App;
