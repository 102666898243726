import React from "react";

export default function HRSolution() {
  const featureAIRecruitingAssistant = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
          />
        </svg>
      ),
      title: "Creates tailored job descriptions to attract ideal candidates",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>
      ),
      title:
        "Sources qualified candidates from multiple platforms and databases ",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
          />
        </svg>
      ),
      title: "Screens resumes and profiles to identify best matches",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
      ),
      title: "Auto-schedules screening calls and interviews",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
          />
        </svg>
      ),
      title: "Sends personalized outreach messages to engage candidates",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
          />
        </svg>
      ),
      title: "Provides sentiment analysis on interactions and feedback",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
          />
        </svg>
      ),
      title: "Streamlines offer process with offer letter generation",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus."
    },
  ];

  const featuresMaximizePerformance = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>
      ),
      title: "Reduce time-to-hire by 40%",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
      ),
      title: "Hire ideal candidates faster with data-driven insights",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
          />
        </svg>
      ),
      title: "Lower recruiting costs by 60%",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
      ),
      title: "Double recruiter productivity",
      // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius."
    },
  ];

  const featuresJobDescription = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
          />
        </svg>
      ),
      title: "Inclusive & Compliant Language",
      desc: "Generative AI ensures proper language appeals to candidates and that your job descriptions meet both state and local compliance requirements",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
          />
        </svg>
      ),
      title: "Advanced Attribute Prioritization and Modeling",
      desc: "Attributes are automatically extracted from JDs showing you what matters most at a glance and assists when discussing requirements with Hiring and TA team and Candidates",
    },

    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
      ),
      title: "Shapes Candidate Search and Selection",
      desc: "No need for Jedi level Boolean skills, attributes and skills requirements are easily adjusted via sliders that inform the external candidate search parameters and the reading and ranking of applicants",
    },
  ];
  const SingleStatus = ({ first, number, label }) => (
    <div className="shadow-sm col-span-4 sm:col-span-1 py-4 m-1 rounded flex justify-start items-center flex-col gap-3.5 grow-0 shrink-0 basis-auto box-border border-r-indigo-100 ">
      <div className="grow- shrink-0 basis-auto box-border w-8 h-5 md:w-8 md:h-8 text-[#1369e9] flex ">
        {first === 1 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="27"
            viewBox="0 0 28 27"
            fill="none"
          >
            <path
              d="M25.9138 20.1193C25.2111 19.4165 22.9955 17.7806 20.7958 16.514C20.7719 16.5497 20.7481 16.5855 20.7242 16.6252C23.2059 12.6268 22.7175 7.30224 19.2432 3.82798C15.1932 -0.21805 8.62983 -0.21805 4.57983 3.82798C0.529834 7.88195 0.533805 14.4414 4.57983 18.4914C8.02233 21.9299 13.2754 22.4501 17.2619 20.0439C18.4094 22.0371 20.081 24.3758 20.8712 25.166C22.2609 26.5557 24.5241 26.5517 25.9138 25.162C27.3036 23.7683 27.3036 21.513 25.9138 20.1193ZM16.5115 15.7596C13.9703 18.2968 9.85679 18.2968 7.31561 15.7556C4.7784 13.2184 4.77443 9.10489 7.31561 6.56371C9.85282 4.02651 13.9703 4.02651 16.5075 6.56371C19.0487 9.10489 19.0487 13.2224 16.5115 15.7596Z"
              fill="#1369E9"
            />
          </svg>
        ) : first === 2 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g
              id="63c1c1804fdd782fd373ecfa_freelancers-icon-workplace-x-webflow-template 2"
              clip-path="url(#clip0_1_21207)"
            >
              <path
                id="Vector"
                d="M13.0369 12.6616C16.5333 12.6616 19.3677 9.8272 19.3677 6.3308C19.3677 2.8344 16.5333 0 13.0369 0C9.54045 0 6.70605 2.8344 6.70605 6.3308C6.70605 9.8272 9.54045 12.6616 13.0369 12.6616Z"
                fill="#80BB01"
              />
              <path
                id="Vector_2"
                d="M23.6523 24.0005C24.235 24.0005 24.7124 23.5271 24.6597 22.9468C24.5535 21.7751 24.2706 20.6242 23.8189 19.5336C23.2323 18.1175 22.3725 16.8307 21.2886 15.7468C20.2047 14.6629 18.918 13.8032 17.5018 13.2166C16.0857 12.63 14.5678 12.3281 13.035 12.3281C11.5021 12.3281 9.9843 12.63 8.56814 13.2166C7.15198 13.8032 5.86522 14.6629 4.78133 15.7468C3.69745 16.8307 2.83767 18.1175 2.25107 19.5336C1.79933 20.6242 1.51642 21.7751 1.41022 22.9468C1.35761 23.5271 1.83496 24.0005 2.4177 24.0005H13.035H23.6523Z"
                fill="#80BB01"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_21207">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.75)"
                />
              </clipPath>
            </defs>
          </svg>
        ) : first === 3 ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g id="vuesax/bold/buliding">
              <g id="buliding">
                <path
                  id="Vector"
                  d="M22.25 21.25H2.25C1.84 21.25 1.5 21.59 1.5 22C1.5 22.41 1.84 22.75 2.25 22.75H22.25C22.66 22.75 23 22.41 23 22C23 21.59 22.66 21.25 22.25 21.25Z"
                  fill="#00AC85"
                />
                <path
                  id="Vector_2"
                  d="M17.25 2H7.25C4.25 2 3.25 3.79 3.25 6V22H21.25V6C21.25 3.79 20.25 2 17.25 2ZM10.25 17.25H7.25C6.84 17.25 6.5 16.91 6.5 16.5C6.5 16.09 6.84 15.75 7.25 15.75H10.25C10.66 15.75 11 16.09 11 16.5C11 16.91 10.66 17.25 10.25 17.25ZM10.25 12.75H7.25C6.84 12.75 6.5 12.41 6.5 12C6.5 11.59 6.84 11.25 7.25 11.25H10.25C10.66 11.25 11 11.59 11 12C11 12.41 10.66 12.75 10.25 12.75ZM10.25 8.25H7.25C6.84 8.25 6.5 7.91 6.5 7.5C6.5 7.09 6.84 6.75 7.25 6.75H10.25C10.66 6.75 11 7.09 11 7.5C11 7.91 10.66 8.25 10.25 8.25ZM17.25 17.25H14.25C13.84 17.25 13.5 16.91 13.5 16.5C13.5 16.09 13.84 15.75 14.25 15.75H17.25C17.66 15.75 18 16.09 18 16.5C18 16.91 17.66 17.25 17.25 17.25ZM17.25 12.75H14.25C13.84 12.75 13.5 12.41 13.5 12C13.5 11.59 13.84 11.25 14.25 11.25H17.25C17.66 11.25 18 11.59 18 12C18 12.41 17.66 12.75 17.25 12.75ZM17.25 8.25H14.25C13.84 8.25 13.5 7.91 13.5 7.5C13.5 7.09 13.84 6.75 14.25 6.75H17.25C17.66 6.75 18 7.09 18 7.5C18 7.91 17.66 8.25 17.25 8.25Z"
                  fill="#00AC85"
                />
              </g>
            </g>
          </svg>
        ) : (
          <div className="flex justify-start items-start mr-[60px] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="114"
              height="20"
              viewBox="0 0 114 20"
              fill="none"
              className="ml-[-30px]"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1688 0.0429688L13.2454 4.53205C13.6944 5.18732 14.3556 5.66765 15.1175 5.89221L20.3377 7.43105L17.0189 11.7444C16.5347 12.3737 16.282 13.1508 16.3039 13.945L16.4535 19.3852L11.3253 17.5616C10.5772 17.2958 9.76 17.2959 9.01192 17.5617L3.88414 19.3852L4.03376 13.9449C4.0556 13.1509 3.80299 12.3737 3.31875 11.7443L0 7.43103L5.22026 5.89219C5.98206 5.66762 6.64316 5.18729 7.09215 4.53217L10.1688 0.0429688Z"
                fill="#FF9500"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M33.5067 0.0429688L36.5833 4.53205C37.0323 5.18732 37.6935 5.66765 38.4553 5.89221L43.6756 7.43105L40.3567 11.7444C39.8726 12.3737 39.6199 13.1508 39.6418 13.945L39.7914 19.3852L34.6632 17.5616C33.9151 17.2958 33.0979 17.2959 32.3498 17.5617L27.222 19.3852L27.3717 13.9449C27.3935 13.1509 27.1409 12.3737 26.6566 11.7443L23.3379 7.43103L28.5581 5.89219C29.32 5.66762 29.9811 5.18729 30.43 4.53217L33.5067 0.0429688Z"
                fill="#FF9500"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.8446 0.0429688L59.9212 4.53205C60.3702 5.18732 61.0314 5.66765 61.7932 5.89221L67.0134 7.43105L63.6946 11.7444C63.2105 12.3737 62.9578 13.1508 62.9797 13.945L63.1293 19.3852L58.0011 17.5616C57.253 17.2958 56.4358 17.2959 55.6877 17.5617L50.5599 19.3852L50.7095 13.9449C50.7314 13.1509 50.4788 12.3737 49.9945 11.7443L46.6758 7.43103L51.896 5.89219C52.6578 5.66762 53.3189 5.18729 53.7679 4.53217L56.8446 0.0429688Z"
                fill="#FF9500"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M80.1815 0.0429688L83.2581 4.53205C83.7071 5.18732 84.3683 5.66765 85.1301 5.89221L90.3504 7.43105L87.0316 11.7444C86.5474 12.3737 86.2947 13.1508 86.3166 13.945L86.4662 19.3852L81.338 17.5616C80.5899 17.2958 79.7727 17.2959 79.0246 17.5617L73.8968 19.3852L74.0465 13.9449C74.0683 13.1509 73.8157 12.3737 73.3314 11.7443L70.0127 7.43103L75.233 5.89219C75.9948 5.66762 76.6559 5.18729 77.1048 4.53217L80.1815 0.0429688Z"
                fill="#FF9500"
              />
              <path
                d="M103.519 0C103.519 6.26165 103.519 10 103.519 10C103.519 10 103.519 9.5 103.519 17C94.7482 20.1169 97.2347 19.3422 97.2347 19.3422L97.3843 13.902C97.4062 13.108 97.1536 12.3308 96.6693 11.7014L93.3506 7.38806L98.5708 5.84923C99.3326 5.62465 99.9937 5.14432 100.443 4.4892L103.519 0Z"
                fill="#FF9500"
              />
              <path
                d="M104.682 17.5188L109.81 19.3422L109.661 13.902C109.639 13.108 109.891 12.3308 110.376 11.7014L113.694 7.38806L108.474 5.84923C107.712 5.62465 107.051 5.14432 106.602 4.4892L103.526 0V17.3351C103.766 17.3351 104.338 17.3964 104.682 17.5188Z"
                fill="#EFF0F2"
              />
            </svg>
          </div>
        )}
      </div>
      <p className="box-border font-bold text-xl lg:text-[40px]  text-[#16182f] grow-0 shrink-0 basis-auto px-[37px]">
        {number}
      </p>
      <p className="grow-0 shrink-0 font-bold basis-auto box-border text-sm  md:font-semibold md:text-base text-[#16182f] mt-[2.5px]">
        {label}
      </p>
    </div>
  );

  const stats = [
    {
      data: "- 35%",
      title: "Recruitment Time",
    },
    {
      data: "+ 90%",
      title: "Recruitment Productivity",
    },
    {
      data: "+ 63%",
      title: "Candidate Engagement",
    },
  ];

  return (
    <>
      <div className="bg-[#F3F5F9] ">
        <div className="flex justify-center">
        <div className="max-w-screen-xl pb-4 px-4 sm:px-8">
        <section class="  sm:grid sm:grid-cols-2 sm:items-center">
          <div class="pr-8 md:pr-12 lg:pr-16 lg:py-24">
            <div class="mx-auto max-w-xl  text-center lg:text-left">
              <h2 class="text-2xl font-bold text-gray-900 md:text-3xl">
                Source Smarter and Hire Faster
              </h2>

              <p class=" text-gray-500 md:mt-4 md:block">
                Finding and hiring top talent takes time, effort and resources.
                SproutsAI makes recruiting 10x more efficient with AI automation
                that handles tedious tasks end-to-end, while you make the final
                hiring decisions. Our AI Recruiting Agents becomes an extension
                of your team to source, screen and engage candidates so you can
                build high-performing teams faster.
              </p>

              <div class="mt-4 md:mt-8">
                <a
                 href="https://beta.sproutsai.com/signup"
                 target="_blank"
                  className="bg-[#1369E9] text-[#FFF] lg:text-xl text-[20px] font-semibold px-2 lg:px-[25px] py-[15px] rounded-[6px] shadow h-[60px]"
                >
                  Get Started Today
                </a>
              </div>
            </div>
          </div>

          <img
            alt="Violin"
            src="https://sproutsweb-assets.s3.amazonaws.com/images/recruitment.jpeg"
            class="h-full w-full hidden md:block lg:block object-cover sm:h-[calc(100%_-_2rem)] sm:self-end sm:rounded-ss-[30px] md:h-[calc(100%_-_4rem)] md:rounded-ss-[60px]"
          />
        </section>
        </div>
        </div>

        {/* 
<div className="mx-auto flex flex-col justify-center items-center mt-[50px]">
        <p className="grow-0 shrink-0 basis-auto box-border text-center text-lg lg:text-[26px] font-semibold text-[black]">
          Our results speak for themselves.
        </p>
        <p className="text-center p-2 lg:p-5 font-light text-md leading-7">
          With our track record of successful placements and satisfied clients,
          we have earned a reputation for delivering exceptional recruitment
          outcomes.
        </p>
        <div className=" flex  flex-wrap justify-between px-[45px] mt-4">
          <div className=" px-[40px]">
            <SingleStatus
              first={1}
              number="500+"
              label="Last Year Placed Talents"
            />
          </div>
          <div className="px-[40px]">
            <SingleStatus
              first={2}
              number="50+"
              label="Specialist Recruiters"
            />
          </div>
          <div className="px-[40px]">
            <SingleStatus first={3} number="30+" label="Happy Companies" />
          </div>
          <div className="px-[40px]">
            <SingleStatus first={4} number="100+" label="4.5 Star Reviews" />
          </div>
        </div>

      </div> */}

        <section className="py-14">
          <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
            <div className="max-w-xl mx-auto space-y-3 sm:text-center">
              <p className="text-black text-3xl font-semibold  sm:text-4xl">
                Meet Hiring Studio: AI Recruiting Assistant
              </p>
            </div>
            <div className="mt-12">
              <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                {featureAIRecruitingAssistant.map((item, idx) => (
                  <li key={idx} className="flex gap-x-4">
                    <div className="flex-none w-12 h-12 bg-indigo-50 text-indigo-600 rounded-lg flex items-center justify-center">
                      {item.icon}
                    </div>
                    <div>
                      <h4 className="text-lg text-gray-800 font-semibold">
                        {item.title}
                      </h4>
                      <p className="mt-3">{item.desc}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        <section className="">
          <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
            {/* <div className="max-w-xl space-y-3">
                    <h3 className="text-indigo-600 font-semibold">
                        Results
                    </h3>
                    <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                    Maximizing Performance
                    </p>

                </div>
                <div className="mt-12">
                    <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            featuresMaximizePerformance.map((item, idx) => (
                                <li key={idx} className="space-y-3">
                                    <div className="w-12 h-12 border text-indigo-600 rounded-lg flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                    </div>
                                    <h4 className="text-lg text-gray-800 font-semibold">
                                        {item.title}
                                    </h4>
                                    <p>
                                        {item.desc}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div> */}
            <section className="py-10 ">
              <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-2xl mx-auto text-center">
                  <h3 className="text-black text-3xl font-semibold sm:text-4xl">
                    Maximizing Performance
                  </h3>
                </div>
                <div className="mt-12">
                  <ul className="flex flex-col gap-4 items-center justify-center sm:flex-row">
                    {stats.map((item, idx) => (
                      <li
                        key={idx}
                        className="w-full text-center bg-[#1369E9] px-12 py-4 rounded-lg sm:w-auto"
                      >
                        <h4 className="text-4xl text-white font-semibold">
                          {item.data}
                        </h4>
                        <p className="mt-3 text-white font-medium">
                          {item.title}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/* <section className="py-1">
        <div className="max-w-screen-xl mx-auto px-4 mb-10 text-gray-600 md:px-8">
            <div className="max-w-xl mx-auto space-y-3 sm:text-center">
                <h3 className="text-blue-600 font-bold text-2xl">
                Key Functionalities of SproutsAI Hiring Studio
                </h3>
                <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                Job Descriptions
                </p>
                
            </div>
            <div className="mt-12">
                <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                    {
                        featuresJobDescription.map((item, idx) => (
                            <li key={idx} className="flex gap-x-4">
                                <div className="flex-none w-12 h-12 bg-indigo-50 text-indigo-600 rounded-lg flex items-center justify-center">
                                    {item.icon}
                                </div>
                                <div>
                                    <h4 className="text-lg text-gray-800 font-semibold">
                                        {item.title}
                                    </h4>
                                    <p className="mt-3">
                                        {item.desc}
                                    </p>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    </section> */}
      </div>
      <section className="py-14 bg-[#F3F5F9]">
        <div className="max-w-screen-xl mx-auto md:px-8">
          <div className="items-center gap-x-12 sm:px-4 md:px-0 lg:flex">
            <div className="flex-1 sm:hidden lg:block">
              <img
                src="images/aicandidate.jpg"
                className="md:max-w-lg sm:rounded-lg"
                alt=""
              />
            </div>
            <div className="max-w-xl px-4 space-y-3 mt-6 sm:px-0 md:mt-0 lg:max-w-2xl">
              <p className="mt-3 text-black">
                Kick-off your hiring process in seconds, not minutes. Create
                position profiles, interview questions, and exercises with the
                help of our LLM powered AI. Say goodbye to tedious manual work
                and hello to efficient and effective hiring with Careers!
              </p>
              <p className="mt-3 text-gray-600">
                Optimize Your Hiring Process with Video Interviews with AI
                Analysis
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
