import { useRef, useState } from "react"

const FaqsCard = (props) => {

    const answerElRef = useRef()
    const [state, setState] = useState(false)
    const [answerH, setAnswerH] = useState('0px')
    const { faqsList, idx } = props

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }

    return (
        <div 
            className="space-y-3 mt-5 overflow-hidden border-b"
            key={idx}
            onClick={handleOpenAnswer}
        >
            <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
                {faqsList.q}
                {
                    state ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                    )
                }
            </h4>
            <div
                ref={answerElRef} className="duration-300"
                style={state ? {height: answerH } : {height: '0px'}}
            >
                <div>
                    <p className="text-gray-500">
                        {faqsList.a}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default function FAQ()  {

    const faqsList = [
        {
            q: "Why is SproutsAI priced per hiring and not an annual subscription?",
            a: "Sprouts AI uses consumption-based pricing. You only pay for what you use. This way, you’re not paying for excess capacity or unused functionality. Our consumption-based pricing is modeled after tech leaders like Snowflake and AWS."
        },
        {
            q: "Can we purchase more candidate bundles during the current license period?",
            a: "Yes, you can always purchase additional hiring bundle credits during the year. However, if you anticipate needing significantly more conversation credits, it’s best to purchase those up front to secure the deepest volume discount."
        },
        {
            q: "Will we receive monthly invoices?",
            a: "Yes. Even though you’re purchasing it up-front, you’ll receive a monthly invoice with the number of hiring credits used, the cost associated with those used credits, and the number of credits remaining in your account."
        },
        {
            q: "What ATS provider the solution integrate with?",
            a: "We currently integrate with Greenhouse, Lever, Ashby, and Workable. If you have requests for other ATS providers, please let us know at talent@sproutsai.com"
        }
    ]
  
    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 p-10 mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-3xl text-gray-800 font-semibold">
                    Frequently Asked Questions
                </h1>
                <p className="text-gray-600 max-w-lg mx-auto text-lg">
                    Answered all frequently asked questions, Still confused? feel free to contact us.
                </p>
            </div>
            <div className="mt-14 max-w-2xl mx-auto">
                {
                    faqsList.map((item, idx) => (
                        <FaqsCard
                            idx={idx}
                            faqsList={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}