import React from 'react'

export default function Product() {
  return (
<>
<section class="bg-[#FFFFFF]">
  <div
    class="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center"
  >
    <div class="mx-auto max-w-xl text-center">
      <h1 class="text-3xl font-extrabold sm:text-4xl">
      Autonomous Agent Platform
        <strong class="font-extrabold text-blue-700 text-2xl sm:block">
        Revolutionize Your Operations with Sproutsai
        </strong>
      </h1>

      <p class="mt-4 text-l">
      Sproutsai is a generative AI-powered autonomous agent platform that
       can help you automate your hiring, sales and marketing, and customer service teams.
        Our AI-powered autonomous agents can handle all your tasks, 24/7, so you can focus
         on growing your business.
      </p>

    </div>
  </div>
</section>
</>
  )
}
